import LoaderComponent from './loader_component';
import ErrorComponent from './error_component';
import OfflineComponent from './offline_component';

export const renderOverlay = (loading = false, mutating = false, online = true) => {
  if (online && (loading || mutating)) {
    return <LoaderComponent />;
  }
  return undefined;
};

export const renderOffline = (online = true) => {
  if (!online) {
    return <OfflineComponent />;
  }
  return undefined;
};

export const renderError = (error) => {
  if (error) {
    return <ErrorComponent error={error} />;
  }
  return undefined;
};
