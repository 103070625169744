export default [
  'assessmentCompletedByName',
  'assessmentCompletedByPhone',
  'assessmentCompletedByEmail',
  'propertyInformationAge',
  'propertyInformationYearPurchased',
  'propertyInformationTypeOfProperty',
  'propertyInformationTypeOfPropertyOther',
  'propertyInformationPropertyUsage',
  'propertyInformationPropertyUsageOther',
  'propertyInformationNumberOfStoreys',
  'propertyInformationNumberOfStoreysOther',
  'propertyInformationFoundation',
  'propertyInformationFoundationOther',
  'propertyInformationExteriorCladding',
  'propertyInformationExteriorCladdingOther',
  'propertyInformationWindowJoinery',
  'propertyInformationWindowJoineryOther',
  'propertyInformationRoofStyle',
  'propertyInformationRoofStyleOther',
  'propertyInformationRoofMaterial',
  'propertyInformationRoofMaterialOther',
  'propertyInformationOverallCondition',
  'propertyInformationAccessIntoProperty',
  'damageAssessmentClaimType',
  'damageAssessmentHighLevelReinstatementEstimate',
  'damageAssessmentCircumstancesAndLikelyCause',
  'damageAssessmentCircumstancesAndLikelyCauseVerification',
  'damageAssessmentResultantDamage',
  'damageAssessmentEvidenceOfPreexistingDamage',
  'damageAssessmentEvidenceOfPreexistingDamageExplanation',
  'damageAssessmentAlternativeAccomodationRecommended',
  'damageAssessmentAlternativeAccomodationRecommendedReason',
  'damageAssessmentAlternativeAccomodationRecommendedNights',
  'damageAssessmentAgeOfFlooringCoverings',
  'waterDamage',
  'waterDamageWaterType',
  'waterDamageWaterSource',
  'waterDamageWaterOriginOther',
  'waterDamageWaterOriginExternal',
  'waterDamageWaterOriginExternalOther',
  'waterDamageWaterOriginInternal',
  'waterDamageWaterOriginInternalOther',
  'waterDamageLengthOfLeak',
  'waterDamageWorkingInVicinityOfLeak',
  'waterDamageWorkingInVicinityOfLeakExplanation',
  'waterDamageDuxQuestPipingFailure',
  'waterDamageDuxQuestPipingFailureExplanation',
  'waterDamageBraidedPipingFailure',
  'waterDamageBraidedPipingFailureExplanation',
  'gradualDamage',
  'gradualDamageRotDecay',
  'gradualDamageMouldMildew',
  'gradualDamageCorrosionRustOxidation',
  'gradualDamageWeatheringFading',
  'gradualDamageLossOfStructuralIntegrity',
  'gradualDamageStainingTideMarks',
  'gradualDamageOtherEvidence',
  'gradualDamageEvidenceFor',
  'gradualDamageOccuringLength',
  'gradualDamageCause',
  'gradualDamageFirstAware',
  'gradualDamagePreventiveSteps',
  'gradualDamageShouldEarlierAware',
  'gradualDamageUnnecessaryDelay',
  'gradualDamageUnnecessaryExpense',
  'tenancy',
  'tenancyTenantName',
  'tenancyTenantPhone',
  'tenancyTenantEmail',
  'tenancyTenantFirstAware',
  'tenancyTenantInformedLandlordOrManager',
  'tenancyTenantInformedLandlordOrManagerWhen',
  'potentialRecovery',
  'potentialRecoveryName',
  'potentialRecoveryPhone',
  'potentialRecoveryEmail',
  'potentialRecoveryAddress',
  'potentialRecoveryReason',
  'potentialNonRecoveryReason',
  'potentialRecoveryAgeOfDamagedArea',
  'plumberOrRoofer',
  'plumberOrRooferName',
  'plumberOrRooferPhone',
  'plumberOrRooferEmail',
  'plumberOrRooferInvoiceAndReportRequested',
  'plumberOrRooferSourceOfLeakRepaired',
  'plumberOrRooferOtherInformation',
  'hazardsAsbestos',
  'hazardsAsbestosDescription',
  'hazardsOther',
  'hazardsOtherDescription',
  'additionalInformationComments',
  'additionalInformationBuilderStory',
];
