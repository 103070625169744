import { Col } from 'react-bootstrap';
import isBoolean from 'lodash.isboolean';

export default function dataRow(dt, dd = null, dtW = 6, ddW = 6) {
  return (
    <>
      <Col as="dt" sm={dtW}>
        {dt}
      </Col>
      <Col as="dd" sm={ddW}>
        {dd &&
          Array.isArray(dd) &&
          dd.map((d, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <span key={`${dt}${index}`}>
              {d}
              <br />
            </span>
          ))}
        {dd && dd === true && 'Yes'}
        {dd && !Array.isArray(dd)}
        {dd || <span className="text-muted">-</span>}
      </Col>
    </>
  );
}
