/* eslint-disable arrow-body-style, no-param-reassign */
import debounce from 'lodash.debounce';
import transform from 'lodash.transform';
import isEqual from 'lodash.isequal';
import isObject from 'lodash.isobject';

const difference = (object, base) => {
  return transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] =
        isObject(value) && isObject(base[key]) ? difference(value, base[key]) : value;
    }
  });
};

const persistDecorator = (options) => (form) => {
  const {
    name,
    debounceTime = 0,
    whitelist = [],
    storage = localStorage,
    blacklist = [],
  } = options;

  const persistedValues = storage.getItem(name) || '{}';
  const { initialValues } = form.getState();
  const diffs = difference(JSON.parse(persistedValues), initialValues);
  // TODO if the user then sets the field back to the last saved value the
  // form is now 'pristine' and wont store the change
  form.batch(() => {
    Object.keys(diffs).forEach((k) => {
      form.change(k, diffs[k]);
    });
  });

  const unsubscribe = form.subscribe(
    debounce(
      ({ values, pristine }) => {
        let valuesKeys = Object.keys(values);
        if (whitelist.length > 0 && !blacklist.length) {
          valuesKeys = Object.keys(values).filter((value) => whitelist.includes(value));
        }
        if (blacklist.length > 0) {
          valuesKeys = Object.keys(values).filter((value) => !blacklist.includes(value));
        }
        const valuesObject = valuesKeys.reduce(
          (acc, key) => ({
            ...acc,
            [key]: values[key],
          }),
          {}
        );

        if (!pristine) {
          storage.setItem(name, JSON.stringify(valuesObject));
        }
      },
      debounceTime,
      { leading: true, trailing: true }
    ),
    { values: true, pristine: true }
  );

  return unsubscribe;
};

const createPersistDecorator = (options) => {
  const { name, storage = localStorage } = options;
  if (!name) {
    throw new Error('createPersistDecorator expects a "name" option');
  }

  const clear = () => {
    storage.removeItem(name);
  };

  const isPersisted = () => !!storage.getItem(name);

  return {
    persistDecorator: persistDecorator(options),
    clear,
    isPersisted,
  };
};

export default createPersistDecorator;
