/* eslint-disable react/jsx-props-no-spreading */
import { useState, cloneElement, Children } from 'react';
import { Button, Modal } from 'react-bootstrap';

import { arrayWithItemAndKey } from '../lib/utils';

const defaultProps = {
  showConfirmButton: true,
  confirmText: 'Confirm',
  confirmVariant: 'primary',
  showCancelButton: true,
  cancelText: 'Cancel',
  cancelVariant: 'secondary',
  showMiddleButton: false,
  middleText: 'Middle',
  middleVariant: 'info',
};

const Confirm = (props) => {
  const {
    children,
    title,
    body,
    //
    showConfirmButton,
    confirmText,
    confirmVariant,
    confirmStyle,
    dataId,
    onConfirm: parentOnConfirm,
    //
    showCancelButton,
    cancelText,
    cancelVariant,
    cancelStyle,
    onCancel: parentOnCancel,
    //
    showMiddleButton,
    middleText,
    middleVariant,
    middleStyle,
    middleId,
    onMiddle: parentOnMiddle,
  } = { ...defaultProps, ...props };

  const [show, setShow] = useState(false);

  const onHide = () => {
    setShow(false);
  };

  const onConfirmClick = (e) => {
    setShow(false);
    if (parentOnConfirm) {
      parentOnConfirm(e);
    }
  };

  const onCancelClick = (e) => {
    setShow(false);
    if (parentOnCancel) {
      parentOnCancel(e);
    }
  };

  const onMiddleClick = (e) => {
    setShow(false);
    if (parentOnMiddle) {
      parentOnMiddle(e);
    }
  };

  const renderBody = () => {
    if (Array.isArray(body)) {
      return (
        <>
          {arrayWithItemAndKey(body).map(({ item, key }) => (
            <p key={key}>{item}</p>
          ))}
        </>
      );
    }
    return body;
  };

  const renderConfirmButton = () => (
    <Button
      variant={confirmVariant}
      onClick={onConfirmClick}
      {...(dataId && { 'data-id': dataId })}
      {...(confirmStyle && { style: confirmStyle })}
    >
      {confirmText}
    </Button>
  );

  const renderCancelButton = () => (
    <Button
      variant={cancelVariant}
      onClick={onCancelClick}
      {...(dataId && { 'data-id': dataId })}
      {...(cancelStyle && { style: cancelStyle })}
    >
      {cancelText}
    </Button>
  );

  const renderMiddleButton = () => (
    <Button
      variant={middleVariant}
      onClick={onMiddleClick}
      {...(middleId && { id: middleId })}
      {...(dataId && { 'data-id': dataId })}
      {...(middleStyle && { style: middleStyle })}
    >
      {middleText}
    </Button>
  );

  return (
    <>
      <Modal show={show} onHide={onHide} animation={false}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderBody()}</Modal.Body>
        <Modal.Footer>
          {showCancelButton && renderCancelButton()}
          {showMiddleButton && renderMiddleButton()}
          {showConfirmButton && renderConfirmButton()}
        </Modal.Footer>
      </Modal>
      {cloneElement(Children.only(children), { onClick: () => setShow(true) })}
    </>
  );
};

export default Confirm;
