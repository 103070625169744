import secondReinspectionFragment from '../fragments/second_reinspection_type_base_fragment';
import cleanCache from './clean_cache';

export const secondReinspectionUpdate = (
  cache,
  { data: { secondReinspectionUpdate: updateData } }
) => {
  const data = updateData;
  cache.writeFragment({
    fragment: secondReinspectionFragment,
    fragmentName: 'SecondReinspectionTypeBase',
    id: `SecondReinspectionType-${updateData.id}`,
    data,
  });
  cleanCache(cache);
};
