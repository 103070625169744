export default [
  'description',
  'qualityIssues',
  'additionalWork',
  'updatedSketch',
  'additionalPhotos',
  'calledTradesman',
  'calledLossAdjustor',
  'tradesmanOnSite',
  'insuredHappyOverall',
  'insuredHappyTradesmanClean',
  'insuredHappyTradesmanPolite',
  'insuredGeneralComments',
];
