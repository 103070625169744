import clone from 'lodash.clone';

import claimClaimItemsFragment from '../fragments/claim_claim_items_fragment';
import claimItemFragment from '../fragments/claim_item_type_base_fragment';

import cleanCache from './clean_cache';

export const claimClaimItemCreate = (
  cache,
  { data: { claimClaimItemCreate: createData } },
  { context: { recordId } }
) => {
  let currentData;
  try {
    currentData = cache.readFragment({
      id: `ClaimType-${createData.claimId}`,
      fragment: claimClaimItemsFragment,
      fragmentName: 'ClaimClaimItemsFragment',
    });
  } catch (err) {
    console.log(err);
    if (window.$NODE_ENV !== 'development') {
      window.Rollbar.info('Failed read fragment', { ...createData, err });
    }
    currentData = null;
  }
  if (currentData) {
    // sometimes the optimistic create obect is not cleared away and remains as part of the collection
    // this is a hack to clean that up. to duplicate, go offline, create an image then a builder report update.
    // go online and optimistic entry will still appear along with the new entry.
    // surprisingly it all works if only item creates are performed
    let currentClaimItems = clone(currentData.claimItems);
    if (createData.id !== recordId) {
      currentClaimItems = currentClaimItems.filter((item) => item.id !== recordId);
    }
    const claimItems = [...currentClaimItems, createData];
    try {
      currentData = cache.writeFragment({
        id: `ClaimType-${createData.claimId}`,
        fragment: claimClaimItemsFragment,
        fragmentName: 'ClaimClaimItemsFragment',
        data: { claimItems },
      });
    } catch (err) {
      console.log(err);
      if (window.$NODE_ENV !== 'development') {
        window.Rollbar.info('Failed write fragment', { ...createData, err });
      }
    }
  }
  cleanCache(cache);
};

export const claimClaimItemDelete = (
  cache,
  { data: { claimClaimItemDelete: deleteData } }
) => {
  let currentData;
  try {
    currentData = cache.readFragment({
      id: `ClaimType-${deleteData.claimId}`, // The value of the to-do item's unique identifier
      fragment: claimClaimItemsFragment,
      fragmentName: 'ClaimClaimItemsFragment',
    });
  } catch (err) {
    console.log(err);
    if (window.$NODE_ENV !== 'development') {
      window.Rollbar.info('Failed read fragment', { ...deleteData, err });
    }
    currentData = null;
  }
  if (currentData) {
    try {
      const claimItems = currentData.claimItems.filter(
        (item) => deleteData.id !== item.id
      );
      currentData = cache.writeFragment({
        id: `ClaimType-${deleteData.claimId}`, // The value of the to-do item's unique identifier
        fragment: claimClaimItemsFragment,
        fragmentName: 'ClaimClaimItemsFragment',
        data: { claimItems },
      });
    } catch (err) {
      console.log(err);
      if (window.$NODE_ENV !== 'development') {
        window.Rollbar.info('Failed write fragment', {
          ...deleteData,
          err,
        });
      }
    }
  }
  cleanCache(cache);
};

export const claimClaimItemUpdate = (
  cache,
  { data: { claimClaimItemUpdate: updateData } }
) => {
  cache.writeFragment({
    fragment: claimItemFragment,
    id: `ClaimItemType-${updateData.id}`,
    data: updateData,
  });
  cleanCache(cache);
};
