import {
  Modal,
  Form,
  Button,
  Row,
  Col,
  ButtonToolbar,
  ButtonGroup,
} from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import arrify from 'arrify';

import curry from 'lodash.curry';
import pick from 'lodash.pick';
import round from 'lodash.round';

import Field from '../components/form/rff_field';
import OnChangeField from '../components/form/rff_on_change_field';
import SubmitButtonSpinner from '../components/submit_button_spinner';
import InputField from '../components/form/input_field';
import InvalidBlock from '../components/form/invalid_block';
import LabelBlock from '../components/form/label_block';
import FocusModalInputField from '../components/form/focus_modal_input_field';
import { pickValues } from '../lib/utils';
import { claimItemFormValidator } from '../validators';

import { claimItemWhiteList } from '../white_lists/claim_item_form_white_lists';

const ClaimItemFormManual = ({
  open,
  selectedFloor,
  selectedRoom,
  lastClaimItem,
  enums,
  workCenterCategoryList,
  claimItem,
  handleSetModalToggle,
  handleClaimItemSubmit,
}) => {
  let clickedSubmitButton = 'new';

  // eslint-disable-next-line arrow-body-style
  const getInitialValues = () => {
    return {
      ...{ itemFloor: selectedFloor },
      ...{ itemRoom: selectedRoom },
      ...(lastClaimItem && pickValues(lastClaimItem, ['itemTrade'])),
      ...pickValues(claimItem, claimItemWhiteList),
    };
  };

  const onHide = () => handleSetModalToggle && handleSetModalToggle();
  const onCancel = () => handleSetModalToggle && handleSetModalToggle();

  const onFormSubmit = (data, form) => {
    handleClaimItemSubmit(data, clickedSubmitButton);
    if (clickedSubmitButton === 'new') {
      form.restart(pick(data, ['itemFloor', 'itemRoom', 'itemTrade']));
    }
  };

  const onItemCostTypeChange = (form, values) => {
    const { itemCostType } = values;
    if (itemCostType === 'Incl.') {
      form.batch(() => {
        form.change('itemQuantity', '');
        form.change('itemPricingUnitPrice', '');
      });
    }
  };

  const onTypeaheadBlur = curry((form, name, e) => {
    e.preventDefault();
    form.mutators.setFieldTouched(name, true);
  });

  const onTypeaheadChange = curry((form, name, value) => {
    let formValue = value[0] || null;
    if (formValue && formValue.id) {
      formValue = formValue.id;
    }
    form.change(name, formValue);
  });

  const onFloatBlur = curry((form, name, onBlur, e) => {
    const value = Number.isNaN(parseFloat(e.target.value))
      ? ''
      : round(parseFloat(e.target.value), 2);
    form.change(name, value);
    onBlur(e);
  });

  const renderContent = () => (
    <>
      <Modal.Header>
        <Modal.Title>{claimItem.id ? 'Update Item' : 'Create Item'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FinalForm
          initialValues={getInitialValues()}
          onSubmit={onFormSubmit}
          validate={claimItemFormValidator}
          mutators={{ setFieldTouched }}
        >
          {({ handleSubmit, pristine, form, submitting, values }) => (
            // <form noValidate onSubmit={handleSubmit}>
            <form onSubmit={handleSubmit} noValidate>
              <Field name="itemRoom">{() => null}</Field>
              <Field name="itemTrade">{() => null}</Field>
              <OnChangeField name="itemCostType">
                {() => onItemCostTypeChange(form, values)}
              </OnChangeField>
              <Row className="mb-3">
                <Field
                  name="itemFloor"
                  component={InputField}
                  asElement="select"
                  selectOptions={enums.ItemFloors.map((floor) => ({
                    id: floor,
                    name: floor,
                  }))}
                  defaultSelectOptionName="select floor..."
                  labelWidth={0}
                  inputWidth={4}
                  size="md"
                >
                  Floor
                </Field>
                <InputField
                  size="md"
                  labelWidth={0}
                  inputWidth={4}
                  input={{
                    name: 'itemRoom',
                  }}
                  innerContent={
                    <>
                      <LabelBlock size="md">Room</LabelBlock>
                      <Typeahead
                        id="itemroom-typeahead-single"
                        labelKey="itemRoom"
                        onBlur={onTypeaheadBlur(form, 'itemRoom')}
                        onChange={onTypeaheadChange(form, 'itemRoom')}
                        options={enums.ItemRooms}
                        placeholder="select room..."
                        selected={arrify(values.itemRoom)}
                      />
                      <InvalidBlock meta={form.getFieldState('itemRoom') || {}} force />
                    </>
                  }
                />
                <InputField
                  size="md"
                  labelWidth={0}
                  inputWidth={4}
                  input={{
                    name: 'itemTrade',
                  }}
                  innerContent={
                    <>
                      <LabelBlock size="md">Work Center</LabelBlock>
                      <Typeahead
                        id="item-trade-typeahead-single"
                        labelKey="workCenterName"
                        onBlur={onTypeaheadBlur(form, 'itemTrade')}
                        onChange={onTypeaheadChange(form, 'itemTrade')}
                        options={workCenterCategoryList
                          .map(({ workCenterName }) => workCenterName)
                          .sort()}
                        placeholder="select workcenter..."
                        selected={arrify(values.itemTrade)}
                      />
                      <InvalidBlock meta={form.getFieldState('itemTrade') || {}} force />
                    </>
                  }
                />
              </Row>
              <hr />
              <Field
                name="itemDescription"
                component={FocusModalInputField}
                asElement="textarea"
                rows={2}
                form={form}
                size="md"
              >
                Description
              </Field>
              <hr />
              <Row>
                <Field
                  name="itemCostType"
                  component={InputField}
                  asElement="select"
                  selectOptions={enums.ItemCostTypes.map((costType) => ({
                    id: costType,
                    name: costType,
                  }))}
                  defaultSelectOptionName="select cost type..."
                  labelWidth={0}
                  inputWidth={4}
                >
                  Cost Type
                </Field>
                <Field
                  name="itemQuantity"
                  component={InputField}
                  customOnBlur={onFloatBlur(form)}
                  disabled={values.itemCostType === 'Incl.'}
                  labelWidth={0}
                  inputWidth={4}
                >
                  Quantity
                </Field>
                <Field
                  name="itemPricingUnitPrice"
                  component={InputField}
                  prepend="$"
                  disabled={values.itemCostType === 'Incl.'}
                  labelWidth={0}
                  inputWidth={4}
                >
                  Unit Price
                </Field>
              </Row>
              <hr />
              <Form.Group as={Row}>
                <Col sm={12}>
                  <ButtonToolbar style={{ justifyContent: 'flex-end' }}>
                    <ButtonGroup className="me-2">
                      <Button variant="danger" onClick={onCancel} disabled={submitting}>
                        Cancel
                      </Button>
                    </ButtonGroup>
                    <ButtonGroup className="me-2">
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={pristine || submitting}
                        onClick={(event) => {
                          clickedSubmitButton = 'new';
                          handleSubmit(event);
                        }}
                      >
                        {submitting && <SubmitButtonSpinner />}
                        {claimItem.id ? 'Update and New' : 'Create and New'}
                      </Button>
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={pristine || submitting}
                        onClick={(event) => {
                          clickedSubmitButton = 'close';
                          handleSubmit(event);
                        }}
                      >
                        {submitting && <SubmitButtonSpinner />}
                        {claimItem.id ? 'Update and Close' : 'Create and Close'}
                      </Button>
                    </ButtonGroup>
                  </ButtonToolbar>
                </Col>
              </Form.Group>
            </form>
          )}
        </FinalForm>
      </Modal.Body>
    </>
  );

  return (
    <div>
      <Modal show={open} onHide={onHide} size="lg" centered>
        {renderContent()}
      </Modal>
    </div>
  );
};

export default ClaimItemFormManual;
