import clone from 'lodash.clone';

import secondReinspectionInternalNotesFragment from '../fragments/second_reinspection_internal_notes_fragment';
import cleanCache from './clean_cache';

export const secondReinspectionInternalNoteCreate = (
  cache,
  { data: { secondReinspectionInternalNoteCreate: createData } },
  { context: { recordId } }
) => {
  let currentData;
  try {
    currentData = cache.readFragment({
      id: `SecondReinspectionType-${createData.internalNoteableId}`,
      fragment: secondReinspectionInternalNotesFragment,
      fragmentName: 'SecondReinspectionInternalNotesFragment',
    });
  } catch (err) {
    console.log(err);
    if (window.$NODE_ENV !== 'development') {
      window.Rollbar.info('Failed read fragment', {
        ...createData,
        err,
      });
    }
    currentData = null;
  }
  if (currentData) {
    let currentInternalNotes = clone(currentData.internalNotes);
    if (createData.id !== recordId) {
      currentInternalNotes = currentInternalNotes.filter(
        (internalNote) => internalNote.id !== recordId
      );
    }
    const internalNotes = [...currentInternalNotes, createData];
    try {
      currentData = cache.writeFragment({
        id: `SecondReinspectionType-${createData.internalNoteableId}`,
        fragment: secondReinspectionInternalNotesFragment,
        fragmentName: 'SecondReinspectionInternalNotesFragment',
        data: { internalNotes },
      });
    } catch (err) {
      console.log(err);
      if (window.$NODE_ENV !== 'development') {
        window.Rollbar.info('Failed write fragment', {
          ...createData,
          err,
        });
      }
    }
  }
  cleanCache(cache);
};

export const secondReinspectionInternalNoteDelete = (
  cache,
  { data: { secondReinspectionInternalNoteDelete: deleteData } }
) => {
  let currentData;
  try {
    currentData = cache.readFragment({
      id: `SecondReinspectionType-${deleteData.internalNoteableId}`,
      fragment: secondReinspectionInternalNotesFragment,
      fragmentName: 'SecondReinspectionInternalNotesFragment',
    });
  } catch (err) {
    console.log(err);
    if (window.$NODE_ENV !== 'development') {
      window.Rollbar.info('Failed read fragment', {
        ...deleteData,
        err,
      });
    }
    currentData = null;
  }
  if (currentData) {
    try {
      const internalNotes = currentData.internalNotes.filter(
        (note) => deleteData.id !== note.id
      );
      currentData = cache.writeFragment({
        id: `SecondReinspectionType-${deleteData.internalNoteableId}`,
        fragment: secondReinspectionInternalNotesFragment,
        fragmentName: 'SecondReinspectionInternalNotesFragment',
        data: { internalNotes },
      });
    } catch (err) {
      console.log(err);
      if (window.$NODE_ENV !== 'development') {
        window.Rollbar.info('Failed write fragment', {
          ...deleteData,
          err,
        });
      }
    }
  }
  cleanCache(cache);
};
