const claimImageFormSchema = {
  type: 'object',
  required: ['files', 'room'],
  properties: {
    room: { type: 'string', minLength: 2, maxLength: 255 },
    room_other: { type: 'string', minLength: 2, maxLength: 255 },
  },
  allOf: [
    {
      if: {
        required: ['room'],
        properties: {
          room: { const: 'Other' },
        },
      },
      then: {
        required: ['roomOther'],
      },
    },
  ],
};

export { claimImageFormSchema };
