export const hazardRequires = ['hazardsAsbestos'];

export const hazardConditionalRules = [
  {
    if: {
      not: {
        properties: {
          hazardsAsbestos: { const: 'No' },
        },
      },
    },
    then: {
      required: ['hazardsAsbestosDescription'],
    },
  },
  {
    if: {
      // boolean so only this required
      required: ['hazardsOther'],
    },
    then: {
      required: ['hazardsOtherDescription'],
    },
  },
];
