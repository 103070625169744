import { Col, Row, Card } from 'react-bootstrap';
import moment from 'moment';

import dataRow from './data_row';

export default function Detail({ claim }) {
  const renderDescription = (description) => description.split('\r\n');

  const {
    oacReferralCreatedAt,
    riStatus,
    riFollowUpStatus,
    caseNo,
    riAssessor,
    claimNo,
    riClientName,
    riContactName,
    riInspector,
    situationOfRisk,
    riJobNumber,
    excess,
    riBusinessPhone,
    claimantMobile,
    claimantPhone,
    riBillingAddress,
    claimantEmail,
    riAssigneeName,
    riScheduledInspectionDate,
    descriptionOfLoss,
    firstReinspection,
    secondReinspection,
  } = claim;
  return (
    <Row>
      <Col>
        <Card className="claimShowDetail">
          <Card.Body>
            <Card.Title>Job Sheet</Card.Title>
            <Row>
              <Col>
                <Row as="dl">
                  {dataRow('Log Date', moment(oacReferralCreatedAt).format('ll'))}
                  {dataRow('Status', riStatus)}
                  {dataRow('Follow Up Status', riFollowUpStatus)}
                  {dataRow('Assessor', riAssessor)}
                  {dataRow('Assessor Ref #', caseNo)}
                  {dataRow('Insurance Claim #', claimNo)}
                  {dataRow('Client Name', riClientName)}
                  {dataRow('Contact Name', riContactName)}
                  {dataRow('Inspector', riInspector)}
                  {dataRow('Address', situationOfRisk.split(','))}
                </Row>
              </Col>
              <Col>
                <Row as="dl">
                  {dataRow('Job Number', riJobNumber)}
                  {dataRow('Property Excess', excess)}
                  {dataRow('Business Phone', riBusinessPhone)}
                  {dataRow('Mobile', claimantMobile)}
                  {dataRow('Private Phone', claimantPhone)}
                  {dataRow('Billing Address', riBillingAddress)}
                  {dataRow('Email Address', claimantEmail)}
                  {dataRow('Who', riAssigneeName)}
                  {dataRow(
                    'Scheduled Inspection Time',
                    moment(riScheduledInspectionDate).format('ll')
                  )}
                </Row>
              </Col>
            </Row>
            <Row as="dl">{dataRow('Details', renderDescription(descriptionOfLoss), 3, 9)}</Row>
            {firstReinspection && (
              <Row as="dl">
                {dataRow(
                  'First Scheduled Reinspection Time',
                  moment(firstReinspection.riScheduledReinspectionDate).format('ll'),
                  3,
                  9
                )}
              </Row>
            )}
            {secondReinspection && (
              <Row as="dl">
                {dataRow(
                  'Second Scheduled Reinspection Time',
                  moment(secondReinspection.riScheduledReinspectionDate).format('ll'),
                  3,
                  9
                )}
              </Row>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
