import { useState, useRef, useMemo } from 'react';
import {
  Card,
  Button,
  Col,
  Row,
  ButtonToolbar,
  ButtonGroup,
  Modal,
  Tabs,
  Tab,
} from 'react-bootstrap';
import { ArrowRight as ArrowRightIcon } from 'react-feather';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Form as FinalForm } from 'react-final-form';
import { capitalCase, sentenceCase } from 'change-case';
import moment from 'moment';

import cloneDeep from 'lodash.clonedeep';
import curry from 'lodash.curry';
import get from 'lodash.get';
import pick from 'lodash.pick';
import pickBy from 'lodash.pickby';

import { settingsSet } from '../store/settings_slice';

import Field from '../components/form/rff_field';
import InputField from '../components/form/input_field';
import CheckboxInputField from '../components/form/checkbox_input_field';
import ToggleRadioInputField from '../components/form/toggle_radio_input_field';
import BooleanRadioInputField from '../components/form/boolean_radio_input_field';
import { renderOverlay, renderError, renderOffline } from '../components/render_helpers';
import SubmitButtonSpinner from '../components/submit_button_spinner';
import DlHorizontal from '../components/dl_horizontal';

import { toastSuccess, toastWarning, toastError } from '../lib/toast_helpers';
import { coerceInput, pickValues, handleSubmitError } from '../lib/utils';
import { qccInspectionWhiteList } from '../white_lists';
import * as updateFunctions from '../update_functions';
import { qccInspectionFormValidator } from '../validators';
import { qccInspectionDefaultValues } from '../defaults';

import { qccInspectionUpdate as qccInspectionUpdateMutation } from '../graphql/qcc_inspection_queries';
import { claimQccInspectionFormPageQuery } from '../graphql/claim_qcc_queries';

const ClaimQccInspectionForm = () => {
  const checkableAttrs = [
    'interiorWorkMeetsQualityStandardsAndSpecifications',
    'interiorScopeCompletedInFull',
    'interiorRepairAreaLeftCleanAndTidy',
    'exteriorWorkMeetsQualityStandardsAndSpecifications',
    'exteriorScopeCompletedInFull',
    'exteriorRepairAreaLeftCleanAndTidy',
  ];
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const focusInputEl = useRef(null);
  const [submitModalShow, setSubmitModalShow] = useState(false);
  const [submitModalErrors, setSubmitModalErrors] = useState([]);
  const [submitModalData, setSubmitModalData] = useState({});
  const [focusModalShow, setFocusModalShow] = useState(false);
  const [focusModalField, setFocusModalField] = useState({
    name: '',
    value: '',
    form: null,
  });
  const [tabKey, setTabKey] = useState('interior');

  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const settingsTenant = useSelector((state) => state.settings.tenant);

  const [qccInspectionUpdate] = useMutation(qccInspectionUpdateMutation);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    networkStatus: pageNetworkStatus,
  } = useQuery(claimQccInspectionFormPageQuery, {
    variables: { claimId: params.id },
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const currentQccInspection = useMemo(() => {
    if (pageData?.claim?.qccInspections) {
      const sortedQccInspections = cloneDeep(
        get(pageData, ['claim', 'qccInspections'], [])
      ).sort((a, b) => b.inspectionDate.localeCompare(a.inspectionDate));
      return get(sortedQccInspections, [0]);
    }
    return undefined;
  }, [pageData]);

  const initialValues = useMemo(() => {
    if (currentQccInspection) {
      return pickValues(currentQccInspection, qccInspectionWhiteList);
    }
    return pickValues(qccInspectionDefaultValues, qccInspectionWhiteList);
  }, [currentQccInspection]);

  const onFormSubmit = async (data) => {
    const submitData = cloneDeep(data);
    submitData.claimId = params.id;
    const mutationData = {
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'QccInspectionType',
        recordId: currentQccInspection.id,
        mutationType: 'UPDATE',
      },
      variables: {
        id: currentQccInspection.id,
        input: coerceInput(submitData),
      },
      update: updateFunctions.qccInspectionUpdate,
    };
    mutationData.optimisticResponse = updateFunctions.optimisticNew({
      mutationName: 'qccInspectionUpdate',
      mutationData,
      currentData: currentQccInspection,
    });
    const mutation = qccInspectionUpdate;
    const mutationMessageAction = 'update';
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await mutation(mutationData);
        toastSuccess(`QCC Inspection ${mutationMessageAction} succeeded`);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        navigate(-1);
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
    } else {
      mutation(mutationData);
      toastWarning(
        `Qcc Inspection ${mutationMessageAction} ok locally. Go online to make permanent`
      );
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      navigate(-1);
    }
    return undefined;
  };

  const hideSubmitModal = () => {
    setSubmitModalErrors([]);
    setSubmitModalData({});
    setSubmitModalShow(false);
  };

  const confirmSubmitModal = () => {
    const submitData = cloneDeep(submitModalData);
    submitData.failedQcc = submitModalErrors.length > 0;
    setSubmitModalErrors([]);
    setSubmitModalData({});
    setSubmitModalShow(false);
    // do the form submission
    onFormSubmit(submitData);
  };

  const onSubmitForm = (data) => {
    const failErrors = Object.keys(pickBy(data, (v) => v === 1));
    const checkableData = pick(data, checkableAttrs);
    const noErrors = Object.keys(pickBy(checkableData, (v) => !v));
    if (failErrors.length > 0 || noErrors.length > 0) {
      setSubmitModalData(data);
      setSubmitModalErrors([...failErrors, ...noErrors]);
      setSubmitModalShow(true);
    } else {
      // do the form submission
      onFormSubmit({ ...data, failedQcc: false });
    }
  };

  const hideFocusModal = () => {
    setFocusModalField({ name: '', value: '', form: null });
    setFocusModalShow(false);
  };

  const saveFocusModal = () => {
    focusModalField.form.change(focusModalField.name, focusInputEl.current.value);
    hideFocusModal();
  };

  const onFocusModal = curry((form, name, onFocus, e) => {
    e.preventDefault();
    window.document.activeElement.blur();
    const fieldState = form.getFieldState(name);
    setFocusModalField({ name, value: fieldState.value || '', form });
    setFocusModalShow(true);
  });

  const enterFocusModal = () => {
    const { value } = focusInputEl.current;
    focusInputEl.current.value = null;
    focusInputEl.current.value = value;
    focusInputEl.current.focus();
  };

  const onCancel = () => {
    navigate(`/claims/${params.id}`);
  };

  const renderToggleRow = (
    switchAttr,
    commentAttr,
    label,
    offText = 'Fail',
    onText = 'Pass'
  ) => (
    <Row>
      <Col xs={5}>
        <Field
          formToggleType="radio"
          name={switchAttr}
          component={ToggleRadioInputField}
          size="lg"
          labelWidth={8}
          inputWidth={4}
          inlineLabel={false}
          inputStyle={{ paddingTop: '0rem' }}
          offText={offText}
          onText={onText}
        >
          {label}
        </Field>
      </Col>
      <Col>
        <Field
          name={commentAttr}
          component={InputField}
          labelWidth={0}
          inputWidth={12}
          size="lg"
        />
      </Col>
    </Row>
  );

  const renderYesNoRow = (
    switchAttr,
    commentAttr,
    label,
    offText = 'No',
    onText = 'Yes'
  ) => (
    <Row>
      <Col xs={5}>
        <Field
          formToggleType="radio"
          name={switchAttr}
          component={BooleanRadioInputField}
          size="lg"
          labelWidth={8}
          inputWidth={4}
          inlineLabel={false}
          inputStyle={{ paddingTop: '0rem' }}
          offText={offText}
          onText={onText}
        >
          {label}
        </Field>
      </Col>
      <Col>
        <Field
          name={commentAttr}
          component={InputField}
          labelWidth={0}
          inputWidth={12}
          size="lg"
        />
      </Col>
    </Row>
  );

  const renderContent = () => {
    const {
      claim: { id },
    } = pageData;
    return (
      <>
        <Modal
          show={focusModalShow}
          onHide={hideFocusModal}
          size="xl"
          onEntered={enterFocusModal}
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title>{`Editing ${capitalCase(focusModalField.name)}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <textarea
                ref={focusInputEl}
                type="text"
                defaultValue={focusModalField.value}
                style={{
                  fontSize: 'large',
                  width: '100%',
                  height: `calc(${window.innerHeight}px - 208px)`,
                  overflowX: 'scroll',
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" variant="danger" onClick={saveFocusModal}>
              Save
            </Button>
            <Button type="button" variant="primary" onClick={hideFocusModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={!!submitModalShow} onHide={hideSubmitModal} centered>
          <Modal.Header>
            <Modal.Title>Confirm Failed QCC Inspection</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              The following fields are failed or incomplete. The QCC Inspection will be
              marked as failed
            </p>
            {submitModalErrors.map((error) => (
              <p key={error}>
                <ArrowRightIcon className="feather-sm me-3" />
                {sentenceCase(error)}
              </p>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" variant="primary" onClick={hideSubmitModal}>
              Continue Editing
            </Button>
            <Button type="button" variant="primary" onClick={confirmSubmitModal}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
        <Row className="mt-4 mb-3">
          <Col sm={12}>
            <div className="float-none">
              <div className="float-start">
                <h1 className="h3 mb-3">Edit QCC Inspection</h1>
              </div>
              <div className="float-end">
                <LinkContainer to="/qcc_inspections">
                  <Button className="me-2" variant="primary">
                    QCC Inspections
                  </Button>
                </LinkContainer>
                <LinkContainer to={`/claims/${id}`}>
                  <Button variant="primary">Job Sheet</Button>
                </LinkContainer>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <FinalForm
              initialValues={initialValues}
              onSubmit={onSubmitForm}
              validate={qccInspectionFormValidator}
            >
              {({ handleSubmit, pristine, submitting, form }) => (
                <form noValidate>
                  <Card className="mb-2">
                    <Card.Body>
                      <DlHorizontal
                        dt="Inspector"
                        dd={get(pageData, 'claim.riInspector')}
                        size="lg"
                        nameWidth={2}
                        descriptionWidth={10}
                      />
                      <DlHorizontal
                        dt="Job Number"
                        dd={get(pageData, 'claim.riJobNumber')}
                        size="lg"
                        nameWidth={2}
                        descriptionWidth={10}
                      />
                      <DlHorizontal
                        dt="Notes for Inspector"
                        dd={currentQccInspection.notesForInspector}
                        size="lg"
                        nameWidth={2}
                        descriptionWidth={10}
                      />
                      <DlHorizontal
                        dt="QCC Inspection Date"
                        dd={moment(currentQccInspection.inspectionDatet).format('ll')}
                        size="lg"
                        nameWidth={2}
                        descriptionWidth={10}
                      />
                      <DlHorizontal
                        dt="&nbsp;"
                        dd={
                          <a
                            className="btn btn-link"
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.building.govt.nz/projects-and-consents/sign-off-and-maintenance/completing-your-project/how-to-identify-defects/tolerances-materials-workmanship-new-residential-construction"
                          >
                            Guide to Tolerances
                          </a>
                        }
                        size="lg"
                        nameWidth={2}
                        descriptionWidth={10}
                      />

                      <hr />
                      <Row>
                        <Col>
                          <Field
                            name="inspectorNotes"
                            component={InputField}
                            labelWidth={4}
                            inputWidth={8}
                            asElement="textarea"
                            size="lg"
                            rows={2}
                            customOnFocus={onFocusModal(form)}
                          >
                            Inspector Notes
                          </Field>
                        </Col>
                        <Col>
                          <Field
                            name="clientNotes"
                            component={InputField}
                            labelWidth={4}
                            inputWidth={8}
                            asElement="textarea"
                            size="lg"
                            rows={2}
                            customOnFocus={onFocusModal(form)}
                          >
                            Insured/Client/Tenant Notes
                          </Field>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Field
                            name="stageOfJobCompletion"
                            component={InputField}
                            asElement="select"
                            selectOptions={get(
                              pageData,
                              'enums.enums.StageOfJobCompletion',
                              []
                            ).map((i) => ({
                              id: i,
                              name: i,
                            }))}
                            defaultSelectOptionName="select stage of job completion..."
                            labelWidth={4}
                            inputWidth={8}
                            size="lg"
                          >
                            Stage of Job Completion
                          </Field>
                        </Col>
                        <Col>
                          <Field
                            type="checkbox"
                            name="metClientOnSite"
                            component={CheckboxInputField}
                            size="lg"
                            labelWidth={4}
                            inputWidth={8}
                          >
                            Met With Client on Site
                          </Field>
                          <Field
                            type="checkbox"
                            name="anotherVisitRequired"
                            component={CheckboxInputField}
                            size="lg"
                            labelWidth={4}
                            inputWidth={8}
                          >
                            Another Visit Required
                          </Field>
                          <Field
                            type="checkbox"
                            name="failedQcc"
                            component={CheckboxInputField}
                            size="lg"
                            labelWidth={4}
                            inputWidth={8}
                          >
                            Failed QCC
                          </Field>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <Card>
                    <Card.Body>
                      <Tabs
                        id="controlled-tab-example"
                        activeKey={tabKey}
                        onSelect={(k) => setTabKey(k)}
                      >
                        <Tab eventKey="interior" title="Interior">
                          <Card>
                            <Card.Body>
                              {renderToggleRow(
                                'interiorCarpentry',
                                'interiorCarpentryComments',
                                'Carpentry Passed?'
                              )}
                              {renderToggleRow(
                                'interiorGibRepairs',
                                'interiorGibRepairsComments',
                                'Gib Repairs Passed?'
                              )}
                              {renderToggleRow(
                                'interiorDecorating',
                                'interiorDecoratingComments',
                                'Decorating Passed?'
                              )}
                              {renderToggleRow(
                                'interiorPlumbing',
                                'interiorPlumbingComments',
                                'Plumbing Passed?'
                              )}
                              {renderToggleRow(
                                'interiorElectricalAlarmsHeatpump',
                                'interiorElectricalAlarmsHeatpumpComments',
                                'Electrical/Alarms/Heatpumps Passed?'
                              )}
                              {renderToggleRow(
                                'interiorFlooring',
                                'interiorFlooringComments',
                                'Flooring Passed?'
                              )}
                              {renderToggleRow(
                                'interiorCabinetmakerBenchtops',
                                'interiorCabinetmakerBenchtopsComments',
                                'CabinetMaker/Benchtops Passed?'
                              )}
                              {renderToggleRow(
                                'interiorTiling',
                                'interiorTilingComments',
                                'Tiling Passed?'
                              )}
                              {renderToggleRow(
                                'interiorOther1',
                                'interiorOther1Comments',
                                'Other1 Passed?'
                              )}
                              {renderToggleRow(
                                'interiorOther2',
                                'interiorOther2Comments',
                                'Other2 Passed?'
                              )}
                              {renderToggleRow(
                                'interiorOther3',
                                'interiorOther3Comments',
                                'Other3 Passed?'
                              )}
                              {renderYesNoRow(
                                'interiorWorkMeetsQualityStandardsAndSpecifications',
                                'interiorWorkMeetsQualityStandardsAndSpecificationsComments',
                                'Work Meets Quality Standards and Specifications?'
                              )}
                              {renderYesNoRow(
                                'interiorScopeCompletedInFull',
                                'interiorScopeCompletedInFullComments',
                                'Scope Completed in Full?'
                              )}
                              {renderYesNoRow(
                                'interiorRepairAreaLeftCleanAndTidy',
                                'interiorRepairAreaLeftCleanAndTidyComments',
                                'Repair Area Left Clean and Tidy?'
                              )}
                            </Card.Body>
                          </Card>
                        </Tab>
                        <Tab eventKey="exterior" title="Exterior">
                          <Card>
                            <Card.Body>
                              {renderToggleRow(
                                'exteriorCarpentry',
                                'exteriorCarpentryComments',
                                'Carpentry Passed?'
                              )}
                              {renderToggleRow(
                                'exteriorPlastering',
                                'exteriorPlasteringComments',
                                'Plastering Passed?'
                              )}
                              {renderToggleRow(
                                'exteriorPainting',
                                'exteriorPaintingComments',
                                'Painting Passed?'
                              )}
                              {renderToggleRow(
                                'exteriorPlumbing',
                                'exteriorPlumbingComments',
                                'Plumbing Passed?'
                              )}
                              {renderToggleRow(
                                'exteriorRoofing',
                                'exteriorRoofingComments',
                                'Roofing Passed?'
                              )}
                              {renderToggleRow(
                                'exteriorSpoutingGuttering',
                                'exteriorSpoutingGutteringComments',
                                'Spouting/Guttering Passed?'
                              )}
                              {renderToggleRow(
                                'exteriorBrickBlockwork',
                                'exteriorBrickBlockworkComments',
                                'Brick/Blockwork Passed?'
                              )}
                              {renderToggleRow(
                                'exteriorConcreteWorks',
                                'exteriorConcreteWorksComments',
                                'Concrete Works Passed?'
                              )}
                              {renderToggleRow(
                                'exteriorTimberAluminiumJoinery',
                                'exteriorTimberAluminiumJoineryComments',
                                'Timber/Aluminium Joinery Passed?'
                              )}
                              {renderToggleRow(
                                'exteriorGarageDoors',
                                'exteriorGarageDoorsComments',
                                'Garage Doors Passed?'
                              )}
                              {renderToggleRow(
                                'exteriorOther1',
                                'exteriorOther1Comments',
                                'Other1 Passed?'
                              )}
                              {renderToggleRow(
                                'exteriorOther2',
                                'exteriorOther2Comments',
                                'Other2 Passed?'
                              )}
                              {renderToggleRow(
                                'exteriorOther3',
                                'exteriorOther3Comments',
                                'Other3 Passed?'
                              )}
                              {renderYesNoRow(
                                'exteriorWorkMeetsQualityStandardsAndSpecifications',
                                'exteriorWorkMeetsQualityStandardsAndSpecificationsComments',
                                'Work Meets Quality Standards and Specifications?'
                              )}
                              {renderYesNoRow(
                                'exteriorScopeCompletedInFull',
                                'exteriorScopeCompletedInFullComments',
                                'Scope Completed in Full?'
                              )}
                              {renderYesNoRow(
                                'exteriorRepairAreaLeftCleanAndTidy',
                                'exteriorRepairAreaLeftCleanAndTidyComments',
                                'Repair Area Left Clean and Tidy?'
                              )}
                            </Card.Body>
                          </Card>
                        </Tab>
                      </Tabs>
                    </Card.Body>
                  </Card>
                  <Row>
                    <Col>
                      <ButtonToolbar style={{ justifyContent: 'flex-end' }}>
                        <ButtonGroup className="me-2">
                          <Button
                            variant="danger"
                            onClick={onCancel}
                            disabled={submitting}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="button"
                            variant="primary"
                            disabled={pristine || submitting}
                            onClick={handleSubmit}
                          >
                            {submitting && <SubmitButtonSpinner />}
                            {params.id ? 'Update' : 'Create'}
                          </Button>
                        </ButtonGroup>
                      </ButtonToolbar>
                    </Col>
                  </Row>
                </form>
              )}
            </FinalForm>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default ClaimQccInspectionForm;
