export const waterDamageRequires = [];

export const waterDamageConditionalRules = [
  {
    if: {
      required: ['waterDamage'],
    },
    then: {
      required: ['waterDamageWaterType'],
    },
  },
  {
    if: {
      required: ['waterDamageWaterSource'],
    },
    then: {
      allOf: [
        {
          if: {
            properties: {
              waterDamageWaterSource: { const: 'Internal Leak' },
            },
          },
          then: {
            required: ['waterDamageWaterOriginInternal'],
          },
        },
        {
          if: {
            properties: {
              waterDamageWaterSource: { const: 'External Leak' },
            },
          },
          then: {
            required: ['waterDamageWaterOriginExternal'],
          },
        },
        {
          if: {
            properties: {
              waterDamageWaterSource: { const: 'Other' },
            },
          },
          then: {
            required: ['waterDamageWaterOriginOther'],
          },
        },
      ],
    },
  },
  {
    if: {
      required: ['waterDamageWaterOriginExternal'],
      properties: {
        waterDamageWaterOriginExternal: { const: 'Other' },
      },
    },
    then: {
      required: ['waterDamageWaterOriginExternalOther'],
    },
  },
  {
    if: {
      required: ['waterDamageWaterOriginInternal'],
      properties: {
        waterDamageWaterOriginInternal: { const: 'Other' },
      },
    },
    then: {
      required: ['waterDamageWaterOriginInternalOther'],
    },
  },
  {
    if: {
      // boolean so only this required
      required: ['waterDamageWorkingInVicinityOfLeak'],
    },
    then: {
      required: ['waterDamageWorkingInVicinityOfLeakExplanation'],
    },
  },
  {
    if: {
      // boolean so only this required
      required: ['waterDamageDuxQuestPipingFailure'],
    },
    then: {
      required: ['waterDamageDuxQuestPipingFailureExplanation'],
    },
  },
  {
    if: {
      // boolean so only this required
      required: ['waterDamageBraidedPipingFailure'],
    },
    then: {
      required: ['waterDamageBraidedPipingFailureExplanation'],
    },
  },
];
