export const propertyInformationRequires = [];

export const propertyInformationConditionalRules = [
  // errorMessage: { required: { propertyInformationTypeOfPropertyOther: 'boo' } },
  {
    if: {
      required: ['propertyInformationTypeOfProperty'],
      properties: {
        propertyInformationTypeOfProperty: { const: 'Other' },
      },
    },
    then: {
      required: ['propertyInformationTypeOfPropertyOther'],
    },
  },
  {
    if: {
      required: ['propertyInformationPropertyUsage'],
      properties: {
        propertyInformationPropertyUsage: { const: 'Other' },
      },
    },
    then: {
      required: ['propertyInformationPropertyUsageOther'],
    },
  },
  {
    if: {
      required: ['propertyInformationNumberOfStoreys'],
      properties: {
        propertyInformationNumberOfStoreys: { const: 'Other' },
      },
    },
    then: {
      required: ['propertyInformationNumberOfStoreysOther'],
    },
  },
  {
    if: {
      required: ['propertyInformationFoundation'],
      properties: {
        propertyInformationFoundation: { const: 'Other' },
      },
    },
    then: {
      required: ['propertyInformationFoundationOther'],
    },
  },
  {
    if: {
      required: ['propertyInformationExteriorCladding'],
      properties: {
        propertyInformationExteriorCladding: { const: 'Other' },
      },
    },
    then: {
      required: ['propertyInformationExteriorCladdingOther'],
    },
  },
  {
    if: {
      required: ['propertyInformationWindowJoinery'],
      properties: {
        propertyInformationWindowJoinery: { const: 'Other' },
      },
    },
    then: {
      required: ['propertyInformationWindowJoineryOther'],
    },
  },
  {
    if: {
      required: ['propertyInformationRoofStyle'],
      properties: {
        propertyInformationRoofStyle: { const: 'Other' },
      },
    },
    then: {
      required: ['propertyInformationRoofStyleOther'],
    },
  },
  {
    if: {
      required: ['propertyInformationRoofMaterial'],
      properties: {
        propertyInformationRoofMaterial: { const: 'Other' },
      },
    },
    then: {
      required: ['propertyInformationRoofMaterialOther'],
    },
  },
];
