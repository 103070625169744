import { Component } from 'react';
import trim from 'lodash.trim';

class ErrorBoundaryApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
    if (window.$NODE_ENV !== 'development') {
      try {
        const exception = {
          path: window.location.href,
          error: error ? error.toString() : 'error',
          errorInfo: errorInfo.componentStack
            .split('\n')
            .map((line) => trim(line))
            .filter((line) => line),
        };
        window.Rollbar.error('[Boundary error]', exception);
      } catch (err) {
        console.log('rollbar fail');
        console.log(err);
        console.log(error);
        console.log(errorInfo);
      }
    }
  }

  render() {
    if (this.state.error) {
      if (window.$NODE_ENV === 'development') {
        return (
          <div>
            <h1>Error Boundary</h1>
            <h3>{this.state.error && this.state.error.toString()}</h3>
            <pre>{this.state.errorInfo.componentStack}</pre>
          </div>
        );
      } else {
        return <p>Something went wrong</p>;
      }
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundaryApp;
