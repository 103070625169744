import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';

import { Col, Row, Card } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';

import get from 'lodash.get';

import { claimClaimImagesSignedUrlUpdate as claimClaimImagesSignedUrlUpdateMutation } from '../../graphql/claim_claim_image_queries';

export default function ClaimImageList({ claimId, claimImages }) {
  const [images, setImages] = useState([]);
  const settingsOnline = useSelector((state) => state.settings.online);
  const [claimClaimImagesSignedUrlUpdate] = useMutation(
    claimClaimImagesSignedUrlUpdateMutation
  );

  useEffect(() => {
    const setNewImages = async () => {
      let newImages = [];
      if (claimImages.length) {
        let filteredImages = claimImages.filter((ci) => ci.signedUrl);
        if (settingsOnline) {
          const mutationData = {
            variables: {
              claimId,
              input: { claimImageIds: filteredImages.map((i) => i.id) },
            },
          };
          const signedUrlUpdates = await claimClaimImagesSignedUrlUpdate(mutationData);
          filteredImages = get(
            signedUrlUpdates,
            ['data', 'claimClaimImagesSignedUrlUpdate'],
            []
          );
        }
        newImages = filteredImages
          .filter((i) => i.id)
          .map(({ signedUrl, fileFilename, room }) => ({
            original: signedUrl,
            description: `${room} - ${fileFilename}`,
          }));
      }
      setImages(newImages);
    };
    setNewImages();
  }, [claimImages, settingsOnline]);

  return (
    <Row>
      <Col xl={10}>
        <Card className="claimShowDetail">
          <Card.Body>
            <Card.Title>Claim Images</Card.Title>
            {images && images.length > 0 && (
              <ImageGallery items={images} showThumbnails={false} showIndex lazyLoad />
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
