import {
  assessmentRequires,
  assessmentConditionalRules,
} from './builder_report_form/assessment_rules';
import {
  propertyInformationRequires,
  propertyInformationConditionalRules,
} from './builder_report_form/property_information_rules';
import {
  damageAssessmentRequires,
  damageAssessmentConditionalRules,
} from './builder_report_form/damage_assessment_rules';
import {
  waterDamageRequires,
  waterDamageConditionalRules,
} from './builder_report_form/water_damage_rules';
import {
  tenancyRequires,
  tenancyConditionalRules,
} from './builder_report_form/tenancy_rules';
import {
  hazardRequires,
  hazardConditionalRules,
} from './builder_report_form/hazard_rules';

const builderReportFormSchema = {
  type: 'object',
  required: [
    ...assessmentRequires,
    ...propertyInformationRequires,
    ...damageAssessmentRequires,
    ...waterDamageRequires,
    ...tenancyRequires,
    ...hazardRequires,
  ],
  allOf: [
    ...assessmentConditionalRules,
    ...propertyInformationConditionalRules,
    ...damageAssessmentConditionalRules,
    ...waterDamageConditionalRules,
    ...tenancyConditionalRules,
    ...hazardConditionalRules,
  ],
};

export { builderReportFormSchema };
