const workCenterItemFormSchema = {
  type: 'object',
  required: ['itemCostType', 'itemDescription', 'itemUnitOfMeasure'],
  allOf: [
    {
      if: {
        properties: {
          itemUnitOfMeasure: { const: 'Min Charge' },
        },
      },
      then: {
        allOf: [
          {
            required: ['minimumChargeQuantity', 'minimumChargeUnitOfMeasure'],
          },
        ],
      },
    },
  ],
};

export { workCenterItemFormSchema };
