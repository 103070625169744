import clone from 'lodash.clone';
import get from 'lodash.get';

import claimClaimItemsFragment from '../fragments/claim_claim_items_fragment';
import cleanCache from './clean_cache';

export const claimClaimItemsCreate = (
  cache,
  { data: { claimClaimItemsCreate: createData } }
) => {
  let currentData;
  const claimId = get(createData, '0.claimId');
  try {
    currentData = cache.readFragment({
      id: `ClaimType-${claimId}`,
      fragment: claimClaimItemsFragment,
      fragmentName: 'ClaimClaimItemsFragment',
    });
  } catch (err) {
    console.log(err);
    if (window.$NODE_ENV !== 'development') {
      window.Rollbar.info('Failed read fragment', { createData, err });
    }
    currentData = null;
  }
  if (currentData) {
    const currentClaimItems = clone(currentData.claimItems);
    const claimItems = [...currentClaimItems, ...createData];
    try {
      currentData = cache.writeFragment({
        id: `ClaimType-${claimId}`,
        fragment: claimClaimItemsFragment,
        fragmentName: 'ClaimClaimItemsFragment',
        data: { claimItems },
      });
    } catch (err) {
      console.log(err);
      if (window.$NODE_ENV !== 'development') {
        window.Rollbar.info('Failed write fragment', { createData, err });
      }
    }
  }
  cleanCache(cache);
};
