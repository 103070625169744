import { useState, useEffect, useMemo } from 'react';
import {
  Col,
  Row,
  Card,
  Button,
  ButtonToolbar,
  ButtonGroup,
  Form,
} from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form as FinalForm } from 'react-final-form';
import { v4 as uuidv4, validate as uuidValidate } from 'uuid';
import { LinkContainer } from 'react-router-bootstrap';

import get from 'lodash.get';
import forEach from 'lodash.foreach';
import cloneDeep from 'lodash.clonedeep';

import ReactTable from '../components/react_table/react_table';
import Field from '../components/form/rff_field';
import InputField from '../components/form/input_field';
import { renderOverlay, renderOffline, renderError } from '../components/render_helpers';
import Confirm from '../components/confirm';
import SubmitButtonSpinner from '../components/submit_button_spinner';

import { settingsSet } from '../store/settings_slice';
import { toastSuccess, toastWarning, toastError } from '../lib/toast_helpers';
import { coerceInput, handleSubmitError } from '../lib/utils';
import { internalNoteFormValidator } from '../validators';
import * as updateFunctions from '../update_functions';

import {
  claimInternalNoteListPageQuery,
  claimInternalNoteCreate as claimInternalNoteCreateMutation,
  claimInternalNoteDelete as claimInternalNoteDeleteMutation,
} from '../graphql/claim_internal_note_queries';
import {
  firstReinspectionInternalNoteCreate as firstReinspectionInternalNoteCreateMutation,
  firstReinspectionInternalNoteDelete as firstReinspectionInternalNoteDeleteMutation,
} from '../graphql/first_reinspection_internal_note_queries';

import {
  secondReinspectionInternalNoteCreate as secondReinspectionInternalNoteCreateMutation,
  secondReinspectionInternalNoteDelete as secondReinspectionInternalNoteDeleteMutation,
} from '../graphql/second_reinspection_internal_note_queries';

const ClaimInternalNoteList = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const settingsTenant = useSelector((state) => state.settings.tenant);

  const [inspectionState, setInspectionState] = useState('');
  const [consolidatedInternalNotes, setConsolidatedInternalNotes] = useState([]);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(claimInternalNoteListPageQuery, {
    notifyOnNetworkStatusChange: true,
    variables: { claimId: params.id },
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const [claimInternalNoteCreate] = useMutation(claimInternalNoteCreateMutation);
  const [claimInternalNoteDelete] = useMutation(claimInternalNoteDeleteMutation);

  const [firstReinspectionInternalNoteCreate] = useMutation(
    firstReinspectionInternalNoteCreateMutation
  );
  const [firstReinspectionInternalNoteDelete] = useMutation(
    firstReinspectionInternalNoteDeleteMutation
  );
  const [secondReinspectionInternalNoteCreate] = useMutation(
    secondReinspectionInternalNoteCreateMutation
  );
  const [secondReinspectionInternalNoteDelete] = useMutation(
    secondReinspectionInternalNoteDeleteMutation
  );

  useEffect(() => {
    setConsolidatedInternalNotes([
      ...get(pageData, 'claim.internalNotes', []),
      ...get(pageData, 'claim.firstReinspection.internalNotes', []),
      ...get(pageData, 'claim.secondReinspection.internalNotes', []),
    ]);
    const { firstReinspection, secondReinspection } = get(pageData, 'claim', {});
    let newInspectionState = '';
    if (!(firstReinspection || secondReinspection)) {
      newInspectionState = 'Claim';
    } else if (secondReinspection) {
      newInspectionState = 'SecondReinspection';
    } else if (firstReinspection) {
      newInspectionState = 'FirstReinspection';
    }
    setInspectionState(newInspectionState);
  }, [pageData]);

  const internalNoteDeleteClicked = async (e) => {
    const dataId = e.currentTarget.getAttribute('data-id');
    const internalNoteId = uuidValidate(dataId)
      ? dataId
      : parseInt(e.currentTarget.getAttribute('data-id'), 10);
    const internalNote = consolidatedInternalNotes.find(
      (note) => note.id === internalNoteId
    );
    const { internalNoteableType, internalNoteableId } = internalNote;
    let mutation;
    let mutationName;
    let updateFunction;
    switch (internalNoteableType) {
      case 'Claim':
        mutation = claimInternalNoteDelete;
        mutationName = 'claimInternalNoteDelete';
        updateFunction = updateFunctions.claimInternalNoteDelete;
        break;
      case 'FirstReinspection':
        mutation = firstReinspectionInternalNoteDelete;
        mutationName = 'firstReinspectionInternalNoteDelete';
        updateFunction = updateFunctions.firstReinspectionInternalNoteDelete;
        break;
      case 'SecondReinspection':
        mutation = secondReinspectionInternalNoteDelete;
        mutationName = 'secondReinspectionInternalNoteDelete';
        updateFunction = updateFunctions.secondReinspectionInternalNoteDelete;
        break;
      default:
        break;
    }
    const mutationData = {
      variables: { internalNoteableId, id: internalNoteId },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'InternalNoteType',
        recordId: internalNoteId,
        mutationType: 'DELETE',
      },
      update: updateFunction,
    };
    mutationData.optimisticResponse = updateFunctions.optimistic(
      mutationName,
      mutationData,
      { internalNoteableId }
    );
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await mutation(mutationData);
        toastSuccess('Internal Note delete ok');
      } catch (err) {
        console.log(err.toString());
        toastError('Internal Note delete failed');
      } finally {
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
      }
    } else {
      mutation(mutationData);
      toastWarning(`Internal Note delete ok locally. Go online to make permanent`);
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
    }
  };

  const internalNoteDeleteAllClicked = () => {
    forEach(consolidatedInternalNotes, (internalNote) => {
      internalNoteDeleteClicked({
        currentTarget: { getAttribute: () => internalNote.id },
      });
    });
  };

  const onFormSubmit = async (data, form) => {
    const uuid = uuidv4();
    const submitData = cloneDeep(data);
    let internalNoteableId;
    let mutation;
    let mutationName;
    let mutationMessageAction;
    let mutationType;
    let updateFunction;
    let recordId;
    switch (!!params.internalNoteId) {
      case false:
        mutationType = 'CREATE';
        mutationMessageAction = 'create';
        recordId = uuid;
        switch (inspectionState) {
          case 'Claim':
            internalNoteableId = get(pageData, 'claim.id');
            mutation = claimInternalNoteCreate;
            mutationName = 'claimInternalNoteCreate';
            updateFunction = updateFunctions.claimInternalNoteCreate;
            break;
          case 'FirstReinspection':
            internalNoteableId = get(pageData, 'claim.firstReinspection.id');
            mutation = firstReinspectionInternalNoteCreate;
            mutationName = 'firstReinspectionInternalNoteCreate';
            updateFunction = updateFunctions.firstReinspectionInternalNoteCreate;
            break;
          case 'SecondReinspection':
            internalNoteableId = get(pageData, 'claim.secondReinspection.id');
            mutation = secondReinspectionInternalNoteCreate;
            mutationName = 'secondReinspectionInternalNoteCreate';
            updateFunction = updateFunctions.secondReinspectionInternalNoteCreate;
            break;
          default:
            break;
        }
        break;
      case true:
        // this is an update, will need completing the mutations etc
        // also the test for whether its an update as these are in page edits.
        mutationType = 'UPDATE';
        mutationMessageAction = 'update';
        recordId = params.internalNoteId;
        switch (inspectionState) {
          case 'Claim':
            internalNoteableId = get(pageData, 'claim.id');
            // mutation = claimInternalNoteUpdate;
            mutationName = 'claimInternalNoteUpdate';
            // updateFunction = updateFunctions.claimInternalNoteUpdate;
            break;
          case 'FirstReinspection':
            internalNoteableId = get(pageData, 'claim.firstReinspection.id');
            // mutation = firstReinspectionInternalNoteUpdate;
            mutationName = 'firstReinspectionInternalNoteUpdate';
            // updateFunction = updateFunctions.firstReinspectionInternalNoteUpdate;
            break;
          case 'SecondReinspection':
            internalNoteableId = get(pageData, 'claim.secondReinspection.id');
            // mutation = secondReinspectionInternalNoteUpdate;
            mutationName = 'secondReinspectionInternalNoteUpdate';
            // updateFunction = updateFunctions.secondReinspectionInternalNoteUpdate;
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
    const input = coerceInput(submitData);
    const mutationData = {
      variables: { internalNoteableId, input },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'InternalNoteType',
        recordId,
        mutationType,
      },
      update: updateFunction,
    };
    mutationData.optimisticResponse = updateFunctions.optimistic(
      mutationName,
      mutationData,
      { internalNoteableId, internalNoteableType: inspectionState }
    );
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await mutation(mutationData);
        toastSuccess(`Internal Note ${mutationMessageAction} succeeded`);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        form.restart();
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
    } else {
      mutation(mutationData);
      toastWarning(
        `Internal Note ${mutationMessageAction} ok locally. Go online to make permanent`
      );
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      form.restart();
    }
    return undefined;
  };

  const internalNoteEditDisabled = (internalNote) => {
    const { internalNoteableType } = internalNote;
    return inspectionState !== internalNoteableType;
  };

  const renderActionsCell = ({ row: { original: row } }) => (
    <Confirm
      dataId={row.id}
      onConfirm={internalNoteDeleteClicked}
      title="Delete Internal Note"
      body="This will DELETE the internal Note."
    >
      <Button
        variant="outline-danger"
        size="small"
        disabled={internalNoteEditDisabled(row)}
      >
        delete
      </Button>
    </Confirm>
  );

  const renderInternalNoteCell = ({ cell }) => (
    <>
      {cell
        .getValue()
        .split('\n')
        .map((desc, descIdx) => (
          <span key={`note-${parseInt(descIdx, 10)}`}>
            <span>{desc}</span>
            <br />
          </span>
        ))}
    </>
  );

  const parentColumns = [
    {
      header: 'Id',
      accessorKey: 'id',
    },
    {
      header: 'Internal Note',
      accessorKey: 'internalNote',
      enableColumnFilter: false,
      cell: renderInternalNoteCell,
    },
    {
      header: 'Internal Note Inspection Type',
      accessorKey: 'internalNoteableType',
      enableColumnFilter: false,
    },
  ];

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm={12}>
          <div className="float-none">
            <div className="float-start">
              <h1 className="h3 mb-3">Internal Notes</h1>
            </div>
            <div className="float-end">
              {(window.$NODE_ENV === 'development' || currentUser.limited) && (
                <ButtonGroup className="me-2">
                  <Button
                    variant="primary"
                    onClick={internalNoteDeleteAllClicked}
                    disabled={!settingsOnline}
                  >
                    Delete all Internal Notes
                  </Button>
                </ButtonGroup>
              )}
              <ButtonGroup>
                <Button
                  variant="primary"
                  onClick={() => pageRefetch()}
                  disabled={!settingsOnline}
                >
                  Refresh
                </Button>
                <LinkContainer to={`/claims/${params.id}`}>
                  <Button variant="primary">Job Sheet</Button>
                </LinkContainer>
              </ButtonGroup>
            </div>
          </div>
        </Col>
      </Row>
      {get(pageData, 'claim.internalNotes') && (
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <ReactTable
                  rootName="claimInternalNote"
                  parentColumns={parentColumns}
                  data={consolidatedInternalNotes}
                  doShow={false}
                  doEdit={false}
                  actionCell={renderActionsCell}
                  hiddenColumns={['id']}
                />
              </Card.Body>
            </Card>
            <FinalForm
              onSubmit={(data, form) => onFormSubmit(data, form)}
              validate={internalNoteFormValidator}
            >
              {({ handleSubmit, pristine, form, submitting }) => (
                <form noValidate>
                  <Card>
                    <Card.Body>
                      <Row className="mb-2">
                        <Col>
                          <Field
                            name="internalNote"
                            component={InputField}
                            asElement="textarea"
                            rows={4}
                            // labelWidth={2}
                            // inputWidth={6}
                            size="lg"
                            hintText="Internal notes are ClaimsDesk only"
                          >
                            Add an Internal Note
                          </Field>
                        </Col>
                      </Row>
                      <Form.Group as={Row}>
                        <Col sm={12}>
                          <ButtonToolbar style={{ justifyContent: 'flex-end' }}>
                            <ButtonGroup className="me-2">
                              <Button
                                variant="danger"
                                onClick={() => form.reset()}
                                disabled={pristine || submitting}
                              >
                                Cancel
                              </Button>
                              <Button
                                type="button"
                                variant="primary"
                                disabled={pristine || submitting}
                                onClick={handleSubmit}
                              >
                                {submitting && <SubmitButtonSpinner />}
                                Create Internal Note
                              </Button>
                            </ButtonGroup>
                          </ButtonToolbar>
                        </Col>
                      </Form.Group>
                    </Card.Body>
                  </Card>
                  <hr />
                </form>
              )}
            </FinalForm>
          </Col>
        </Row>
      )}
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default ClaimInternalNoteList;
