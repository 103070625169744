import { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export default function DropZone({ filenames, handleOnDrop, multiple = false }) {
  const onDrop = useCallback((acceptedFiles) => {
    if (handleOnDrop) {
      handleOnDrop(acceptedFiles);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    // accept: ['text/csv', 'application/vnd.ms-excel', 'text/plain'],
    multiple,
    maxSize: 41943040,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject]
  );

  const renderMessage = () => {
    if (isDragActive) {
      return <p>Drop file here...</p>;
    } else if (filenames) {
      return (
        <>
          <p>Drag and drop a file here, or click to replace:</p>
          {filenames.map((filename) => (
            <p key={filename}>
              <strong className="text-primary">{filename}</strong>
            </p>
          ))}
        </>
      );
    } else {
      return <p>Drag and drop a file here, or click to upload</p>;
    }
  };

  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      {renderMessage()}
    </div>
  );
}
