import { Component } from 'react';
import { FormControl } from 'react-bootstrap';

class BlurOnlyFormControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
    this.onChange = this.onChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.value) {
      this.setState({
        value: nextProps.value,
      });
    }
  }

  onChange(e) {
    this.setState({
      value: e.target.value,
    });
  }

  render() {
    const newProps = {
      ...this.props,
      onChange: this.onChange,
      value: this.state.value,
    };

    return <FormControl {...newProps} />;
  }
}

export default BlurOnlyFormControl;
