import { Col, Row, Card } from 'react-bootstrap';
import { capitalCase } from 'change-case';

import dataRow from './data_row';

export default function BuilderReport({ builderReport }) {
  const getDataRow = (vars) =>
    dataRow(capitalCase(Object.keys(vars)[0]), Object.values(vars)[0], 3, 9);

  const {
    assessmentCompletedByName,
    assessmentCompletedByPhone,
    assessmentCompletedByEmail,
    propertyInformationAge,
    propertyInformationYearPurchased,
    propertyInformationTypeOfProperty,
    propertyInformationTypeOfPropertyOther,
    propertyInformationPropertyUsage,
    propertyInformationPropertyUsageOther,
    propertyInformationNumberOfStoreys,
    propertyInformationNumberOfStoreysOther,
    propertyInformationFoundation,
    propertyInformationFoundationOther,
    propertyInformationExteriorCladding,
    propertyInformationExteriorCladdingOther,
    propertyInformationWindowJoinery,
    propertyInformationWindowJoineryOther,
    propertyInformationRoofStyle,
    propertyInformationRoofStyleOther,
    propertyInformationRoofMaterial,
    propertyInformationRoofMaterialOther,
    propertyInformationOverallCondition,
    propertyInformationAccessIntoProperty,
    damageAssessmentClaimType,
    damageAssessmentHighLevelReinstatementEstimate,
    damageAssessmentCircumstancesAndLikelyCause,
    damageAssessmentCircumstancesAndLikelyCauseVerification,
    damageAssessmentResultantDamage,
    damageAssessmentEvidenceOfPreexistingDamage,
    damageAssessmentEvidenceOfPreexistingDamageExplanation,
    damageAssessmentAlternativeAccomodationRecommended,
    damageAssessmentAlternativeAccomodationRecommendedReason,
    damageAssessmentAlternativeAccomodationRecommendedNights,
    damageAssessmentAgeOfFlooringCoverings,
    waterDamage,
    waterDamageWaterType,
    waterDamageWaterSource,
    waterDamageWaterOriginOther,
    waterDamageWaterOriginExternal,
    waterDamageWaterOriginExternalOther,
    waterDamageWaterOriginInternal,
    waterDamageWaterOriginInternalOther,
    waterDamageLengthOfLeak,
    waterDamageWorkingInVicinityOfLeak,
    waterDamageWorkingInVicinityOfLeakExplanation,
    waterDamageDuxQuestPipingFailure,
    waterDamageDuxQuestPipingFailureExplanation,
    waterDamageBraidedPipingFailure,
    waterDamageBraidedPipingFailureExplanation,
    gradualDamage,
    gradualDamageRotDecay,
    gradualDamageMouldMildew,
    gradualDamageCorrosionRustOxidation,
    gradualDamageWeatheringFading,
    gradualDamageLossOfStructuralIntegrity,
    gradualDamageStainingTideMarks,
    gradualDamageOtherEvidence,
    gradualDamageEvidenceFor,
    gradualDamageOccuringLength,
    gradualDamageCause,
    gradualDamageFirstAware,
    gradualDamagePreventiveSteps,
    gradualDamageShouldEarlierAware,
    gradualDamageUnnecessaryDelay,
    gradualDamageUnnecessaryExpense,
    tenancy,
    tenancyTenantName,
    tenancyTenantPhone,
    tenancyTenantEmail,
    tenancyTenantFirstAware,
    tenancyTenantInformedLandlordOrManager,
    tenancyTenantInformedLandlordOrManagerWhen,
    potentialRecovery,
    potentialRecoveryName,
    potentialRecoveryPhone,
    potentialRecoveryEmail,
    potentialRecoveryAddress,
    potentialRecoveryReason,
    potentialNonRecoveryReason,
    potentialRecoveryAgeOfDamagedArea,
    plumberOrRoofer,
    plumberOrRooferName,
    plumberOrRooferPhone,
    plumberOrRooferEmail,
    plumberOrRooferInvoiceAndReportRequested,
    plumberOrRooferSourceOfLeakRepaired,
    plumberOrRooferOtherInformation,
    hazardsAsbestos,
    hazardsAsbestosDescription,
    hazardsOther,
    hazardsOtherDescription,
    additionalInformationComments,
    additionalInformationBuilderStory,
  } = builderReport;

  return (
    <Row>
      <Col>
        <Card className="claimShowDetail">
          <Card.Body>
            <Card.Title>Builder Report</Card.Title>
            <Card.Subtitle>Assessor</Card.Subtitle>
            <Row as="dl">
              {getDataRow({ assessmentCompletedByName })}
              {getDataRow({ assessmentCompletedByPhone })}
              {getDataRow({ assessmentCompletedByEmail })}
            </Row>
            <Card.Subtitle>Property Information</Card.Subtitle>
            <Row as="dl">
              {getDataRow({ propertyInformationAge })}
              {getDataRow({ propertyInformationYearPurchased })}
              {getDataRow({ propertyInformationTypeOfProperty })}
              {getDataRow({ propertyInformationTypeOfPropertyOther })}
              {getDataRow({ propertyInformationPropertyUsage })}
              {getDataRow({ propertyInformationPropertyUsageOther })}
              {getDataRow({ propertyInformationNumberOfStoreys })}
              {getDataRow({ propertyInformationNumberOfStoreysOther })}
              {getDataRow({ propertyInformationFoundation })}
              {getDataRow({ propertyInformationFoundationOther })}
              {getDataRow({ propertyInformationExteriorCladding })}
              {getDataRow({ propertyInformationExteriorCladdingOther })}
              {getDataRow({ propertyInformationWindowJoinery })}
              {getDataRow({ propertyInformationWindowJoineryOther })}
              {getDataRow({ propertyInformationRoofStyle })}
              {getDataRow({ propertyInformationRoofStyleOther })}
              {getDataRow({ propertyInformationRoofMaterial })}
              {getDataRow({ propertyInformationRoofMaterialOther })}
              {getDataRow({ propertyInformationOverallCondition })}
              {getDataRow({ propertyInformationAccessIntoProperty })}
            </Row>
            <Card.Subtitle>Damage Assessment</Card.Subtitle>
            <Row as="dl">
              {getDataRow({ damageAssessmentClaimType })}
              {getDataRow({ damageAssessmentHighLevelReinstatementEstimate })}
              {getDataRow({ damageAssessmentCircumstancesAndLikelyCause })}
              {getDataRow({ damageAssessmentCircumstancesAndLikelyCauseVerification })}
              {getDataRow({ damageAssessmentResultantDamage })}
              {getDataRow({ damageAssessmentEvidenceOfPreexistingDamage })}
              {getDataRow({ damageAssessmentEvidenceOfPreexistingDamageExplanation })}
              {getDataRow({ damageAssessmentAlternativeAccomodationRecommended })}
              {getDataRow({ damageAssessmentAlternativeAccomodationRecommendedReason })}
              {getDataRow({ damageAssessmentAlternativeAccomodationRecommendedNights })}
              {getDataRow({ damageAssessmentAgeOfFlooringCoverings })}
            </Row>
            <Card.Subtitle>Water Damage</Card.Subtitle>
            <Row as="dl">
              {getDataRow({ waterDamage })}
              {getDataRow({ waterDamageWaterType })}
              {getDataRow({ waterDamageWaterSource })}
              {getDataRow({ waterDamageWaterOriginOther })}
              {getDataRow({ waterDamageWaterOriginExternal })}
              {getDataRow({ waterDamageWaterOriginExternalOther })}
              {getDataRow({ waterDamageWaterOriginInternal })}
              {getDataRow({ waterDamageWaterOriginInternalOther })}
              {getDataRow({ waterDamageLengthOfLeak })}
              {getDataRow({ waterDamageWorkingInVicinityOfLeak })}
              {getDataRow({ waterDamageWorkingInVicinityOfLeakExplanation })}
              {getDataRow({ waterDamageDuxQuestPipingFailure })}
              {getDataRow({ waterDamageDuxQuestPipingFailureExplanation })}
              {getDataRow({ waterDamageBraidedPipingFailure })}
              {getDataRow({ waterDamageBraidedPipingFailureExplanation })}
            </Row>
            <Card.Subtitle>Gradual Damage</Card.Subtitle>
            <Row as="dl">
              {getDataRow({ gradualDamage })}
              {getDataRow({ gradualDamageRotDecay })}
              {getDataRow({ gradualDamageMouldMildew })}
              {getDataRow({ gradualDamageCorrosionRustOxidation })}
              {getDataRow({ gradualDamageWeatheringFading })}
              {getDataRow({ gradualDamageLossOfStructuralIntegrity })}
              {getDataRow({ gradualDamageStainingTideMarks })}
              {getDataRow({ gradualDamageOtherEvidence })}
              {getDataRow({ gradualDamageEvidenceFor })}
              {getDataRow({ gradualDamageOccuringLength })}
              {getDataRow({ gradualDamageCause })}
              {getDataRow({ gradualDamageFirstAware })}
              {getDataRow({ gradualDamagePreventiveSteps })}
              {getDataRow({ gradualDamageShouldEarlierAware })}
              {getDataRow({ gradualDamageUnnecessaryDelay })}
              {getDataRow({ gradualDamageUnnecessaryExpense })}
            </Row>
            <Card.Subtitle>Tenancy</Card.Subtitle>
            <Row as="dl">
              {getDataRow({ tenancy })}
              {getDataRow({ tenancyTenantName })}
              {getDataRow({ tenancyTenantPhone })}
              {getDataRow({ tenancyTenantEmail })}
              {getDataRow({ tenancyTenantFirstAware })}
              {getDataRow({ tenancyTenantInformedLandlordOrManager })}
              {getDataRow({ tenancyTenantInformedLandlordOrManagerWhen })}
            </Row>
            <Card.Subtitle>Potential Recovery</Card.Subtitle>
            <Row as="dl">
              {getDataRow({ potentialRecovery })}
              {getDataRow({ potentialRecoveryName })}
              {getDataRow({ potentialRecoveryPhone })}
              {getDataRow({ potentialRecoveryEmail })}
              {getDataRow({ potentialRecoveryAddress })}
              {getDataRow({ potentialRecoveryReason })}
              {getDataRow({ potentialNonRecoveryReason })}
              {getDataRow({ potentialRecoveryAgeOfDamagedArea })}
            </Row>
            <Card.Subtitle>Plumber or Roofer</Card.Subtitle>
            <Row as="dl">
              {getDataRow({ plumberOrRoofer })}
              {getDataRow({ plumberOrRooferName })}
              {getDataRow({ plumberOrRooferPhone })}
              {getDataRow({ plumberOrRooferEmail })}
              {getDataRow({ plumberOrRooferInvoiceAndReportRequested })}
              {getDataRow({ plumberOrRooferSourceOfLeakRepaired })}
              {getDataRow({ plumberOrRooferOtherInformation })}
            </Row>
            <Card.Subtitle>Hazards</Card.Subtitle>
            <Row as="dl">
              {getDataRow({ hazardsAsbestos })}
              {getDataRow({ hazardsAsbestosDescription })}
              {getDataRow({ hazardsOther })}
              {getDataRow({ hazardsOtherDescription })}
            </Row>
            <Card.Subtitle>Additional Information</Card.Subtitle>
            <Row as="dl">
              {getDataRow({ additionalInformationComments })}
              {getDataRow({ additionalInformationBuilderStory })}
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
