import { useMemo } from 'react';
import { Col, Row, Card, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useQuery, NetworkStatus } from '@apollo/client';
import { useSelector } from 'react-redux';
import moment from 'moment';

import ReactTable from '../components/react_table/react_table';
import { renderOverlay, renderOffline, renderError } from '../components/render_helpers';

import { claimListQuery } from '../graphql/claim_queries';

import * as updateFunctions from '../update_functions';

const ClaimInspectedList = () => {
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
    client: pageClient,
  } = useQuery(claimListQuery, {
    notifyOnNetworkStatusChange: true,
    variables: { listType: 'inspectorInspectedList' },
    onCompleted: ({ claimList }) => updateFunctions.claimList(pageClient, claimList),
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const renderDateCell = (date) => moment(date).format('ll');
  // const renderDateTimeCell = (cell) => moment(cell).format('lll');

  const renderAddressCell = (cell) =>
    cell
      .getValue()
      .split(',')
      .map((c) => (
        <span key={c}>
          {c}
          <br />
        </span>
      ));

  const renderActionsCell = ({ row: { original: row } }) => (
    <LinkContainer to={`/claims/${row.id}`}>
      <Button variant="outline-primary" size="small" className="w-100 mb-2">
        Job Sheet
      </Button>
    </LinkContainer>
  );

  const parentColumns = [
    {
      header: 'Id',
      accessorKey: 'id',
    },
    {
      header: 'Job Number',
      accessorKey: 'riJobNumber',
      filterFn: 'includesString',
    },
    {
      header: 'Claim Number',
      accessorKey: 'claimNo',
      filterFn: 'includesString',
    },
    {
      header: 'Inspector',
      accessorKey: 'riInspector',
      filterType: 'dropdown',
    },
    {
      header: 'Client Name',
      accessorKey: 'riClientName',
      filterFn: 'includesString',
    },
    {
      header: 'Address',
      accessorKey: 'situationOfRisk',
      filterFn: 'includesString',
      cell: ({ cell }) => renderAddressCell(cell),
    },
    {
      header: 'Scheduled At',
      accessorKey: 'riScheduledInspectionDate',
      enableColumnFilter: false,
      accessorFn: ({ riScheduledInspectionDate }) =>
        renderDateCell(riScheduledInspectionDate),
    },

    {
      header: 'Inspected On',
      accessorKey: 'submittedAt',
      enableColumnFilter: false,
      accessorFn: ({ submittedAt }) => renderDateCell(submittedAt),
    },
    {
      header: 'Job Status',
      accessorKey: 'riStatus',
      filterType: 'dropdown',
    },
  ];

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm={12}>
          <div className="float-none">
            <div className="float-start">
              <h1 className="h3 mb-3">Completed Inspections</h1>
            </div>
            <div className="float-end">
              <Button
                variant="primary"
                onClick={() => pageRefetch()}
                disabled={!settingsOnline}
              >
                Refresh
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <ReactTable
                rootName="claimInspected"
                parentColumns={parentColumns}
                data={pageData.claimList}
                doShow={false}
                doEdit={false}
                actionCell={renderActionsCell}
                hiddenColumns={['id']}
                setTableClasses={() => 'table-layout-fixed'}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default ClaimInspectedList;
