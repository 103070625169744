/* eslint-disable react/jsx-props-no-spreading, react/no-children-prop */
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

import InputField from './input_field';

const defaultProps = {
  formToggleType: 'checkbox',
  size: 'sm',
  labelWidth: 3,
  inputWidth: 9,
  inlineLabel: true,
  controlOnly: false,
  innerContentOnly: false,
  naText: 'N/A',
  offText: 'Off',
  onText: 'On',
};

const ToggleRadioInputField = (props) => {
  const {
    input,
    children,
    formToggleType,
    size,
    labelWidth,
    inputWidth,
    inlineLabel,
    className,
    controlOnly,
    innerContentOnly,
    inputStyle,
    naText,
    offText,
    onText,
  } = { ...defaultProps, ...props };
  return (
    <InputField
      {...(!inlineLabel && { children })}
      size={size}
      labelWidth={labelWidth}
      inputWidth={inputWidth}
      input={{
        name: input.name,
      }}
      controlOnly={controlOnly}
      innerContentOnly={innerContentOnly}
      innerContent={
        <ToggleButtonGroup
          size="lg"
          type={formToggleType}
          name={input.name}
          {...(className && { className })}
          {...(inputStyle && { style: inputStyle })}
          {...((controlOnly || innerContentOnly) && { id: input.name })}
          value={input.value}
          onChange={input.onChange}
        >
          <ToggleButton
            id={`${input.name}-0`}
            value={0}
            variant={input.value === 0 ? 'primary' : 'light'}
          >
            {naText}
          </ToggleButton>
          <ToggleButton
            id={`${input.name}-1`}
            value={1}
            variant={input.value === 1 ? 'primary' : 'light'}
          >
            {offText}
          </ToggleButton>
          <ToggleButton
            id={`${input.name}-2`}
            value={2}
            variant={input.value === 2 ? 'primary' : 'light'}
          >
            {onText}
          </ToggleButton>
        </ToggleButtonGroup>
      }
    />
  );
};

export default ToggleRadioInputField;
