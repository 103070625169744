import makeFormValidator from './make_form_validator';

import { loginFormSchema } from './login_form_schema';
import { internalNoteFormSchema } from './internal_note_form_schema';
import { claimImageFormSchema } from './claim_image_form_schema';
import { claimItemFormSchema } from './claim_item_form_schema';
import { builderReportFormSchema } from './builder_report_form_schema';
import { userFormSchema } from './user_form_schema';
import { reinspectionFormSchema } from './reinspection_form_schema';
import { qccInspectionFormSchema } from './qcc_inspection_form_schema';
import { workCenterItemFormSchema } from './work_center_item_form_schema';
import { claimItemListCloneRoomFormSchema } from './claim_item_list_clone_room_form_schema';

const loginFormValidator = makeFormValidator(loginFormSchema);
const internalNoteFormValidator = makeFormValidator(internalNoteFormSchema);
const claimImageFormValidator = makeFormValidator(claimImageFormSchema);
const claimItemFormValidator = makeFormValidator(claimItemFormSchema);
const builderReportFormValidator = makeFormValidator(builderReportFormSchema);
const userFormValidator = makeFormValidator(userFormSchema);
const reinspectionFormValidator = makeFormValidator(reinspectionFormSchema);
const qccInspectionFormValidator = makeFormValidator(qccInspectionFormSchema);
const workCenterItemFormValidator = makeFormValidator(workCenterItemFormSchema);
const claimItemListCloneRoomFormValidator = makeFormValidator(
  claimItemListCloneRoomFormSchema
);

export {
  builderReportFormValidator,
  claimImageFormValidator,
  claimItemFormValidator,
  loginFormValidator,
  userFormValidator,
  reinspectionFormValidator,
  qccInspectionFormValidator,
  workCenterItemFormValidator,
  internalNoteFormValidator,
  claimItemListCloneRoomFormValidator,
};
