import clone from 'lodash.clone';

import claimInternalNotesFragment from '../fragments/claim_internal_notes_fragment';
import cleanCache from './clean_cache';

export const claimInternalNoteCreate = (
  cache,
  { data: { claimInternalNoteCreate: createData } },
  { context: { recordId } }
) => {
  let currentData;
  try {
    currentData = cache.readFragment({
      id: `ClaimType-${createData.internalNoteableId}`,
      fragment: claimInternalNotesFragment,
      fragmentName: 'ClaimInternalNotesFragment',
    });
  } catch (err) {
    console.log(err);
    if (window.$NODE_ENV !== 'development') {
      window.Rollbar.info('Failed read fragment', { ...createData, err });
    }
    currentData = null;
  }
  if (currentData) {
    // sometimes the optimistic create obect is not cleared away and remains as part of the collection
    // this is a hack to clean that up. to duplicate, go offline, create an image then a builder report update.
    // go online and optimistic entry will still appear along with the new entry.
    // surprisingly it all works if only image updates are performed
    let currentInternalNotes = clone(currentData.internalNotes);
    if (createData.id !== recordId) {
      currentInternalNotes = currentInternalNotes.filter(
        (internalNote) => internalNote.id !== recordId
      );
    }
    const internalNotes = [...currentInternalNotes, createData];
    try {
      currentData = cache.writeFragment({
        id: `ClaimType-${createData.internalNoteableId}`,
        fragment: claimInternalNotesFragment,
        fragmentName: 'ClaimInternalNotesFragment',
        data: { internalNotes },
      });
    } catch (err) {
      console.log(err);
      if (window.$NODE_ENV !== 'development') {
        window.Rollbar.info('Failed write fragment', { ...createData, err });
      }
    }
  }
  cleanCache(cache);
};

export const claimInternalNoteDelete = (
  cache,
  { data: { claimInternalNoteDelete: deleteData } }
) => {
  let currentData;
  try {
    currentData = cache.readFragment({
      // TODO THESE NEED POLYMORPHIC-ING
      id: `ClaimType-${deleteData.internalNoteableId}`,
      fragment: claimInternalNotesFragment,
      fragmentName: 'ClaimInternalNotesFragment',
    });
  } catch (err) {
    console.log(err);
    if (window.$NODE_ENV !== 'development') {
      window.Rollbar.info('Failed read fragment', { ...deleteData, err });
    }
    currentData = null;
  }
  if (currentData) {
    try {
      const internalNotes = currentData.internalNotes.filter(
        (note) => deleteData.id !== note.id
      );
      currentData = cache.writeFragment({
        id: `ClaimType-${deleteData.internalNoteableId}`,
        fragment: claimInternalNotesFragment,
        fragmentName: 'ClaimInternalNotesFragment',
        data: { internalNotes },
      });
    } catch (err) {
      console.log(err);
      if (window.$NODE_ENV !== 'development') {
        window.Rollbar.info('Failed write fragment', {
          ...deleteData,
          err,
        });
      }
    }
  }
  cleanCache(cache);
};
