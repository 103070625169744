import { useState, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { capitalCase } from 'change-case';

import curry from 'lodash.curry';

import InputField from './input_field';

const FocusModalInputField = (props) => {
  const focusInputEl = useRef(null);
  const [focusModalShow, setFocusModalShow] = useState(false);
  const [focusModalField, setFocusModalField] = useState({
    name: '',
    value: '',
    form: null,
  });
  const hideFocusModal = () => {
    setFocusModalField({ name: '', value: '', form: null });
    setFocusModalShow(false);
  };

  const saveFocusModal = () => {
    focusModalField.form.change(focusModalField.name, focusInputEl.current.value);
    hideFocusModal();
  };
  const onFocusModal = curry((form, name, onFocus, e) => {
    e.preventDefault();
    window.document.activeElement.blur();
    const fieldState = form.getFieldState(name);
    setFocusModalField({ name, value: fieldState.value || '', form });
    setFocusModalShow(true);
  });

  const enterFocusModal = () => {
    const { value } = focusInputEl.current;
    focusInputEl.current.value = null;
    focusInputEl.current.value = value;
    focusInputEl.current.focus();
  };

  return (
    <>
      <Modal
        show={focusModalShow}
        onHide={hideFocusModal}
        size="xl"
        onEntered={enterFocusModal}
      >
        <Modal.Header>
          <Modal.Title>{`Editing ${capitalCase(focusModalField.name)}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <textarea
              ref={focusInputEl}
              type="text"
              defaultValue={focusModalField.value}
              style={{
                fontSize: 'large',
                width: '100%',
                height: `calc(${window.innerHeight}px - 208px)`,
                overflowX: 'scroll',
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="danger" onClick={saveFocusModal}>
            Save
          </Button>
          <Button type="button" variant="primary" onClick={hideFocusModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <InputField {...props} customOnFocus={onFocusModal(props.form)} />
    </>
  );
};

export default FocusModalInputField;
