import { useState, useMemo } from 'react';
import { Button, Col, Row, ButtonGroup, Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { useQuery, NetworkStatus, useMutation, useLazyQuery } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { validate as uuidValidate } from 'uuid';
import { capitalCase } from 'change-case';
import { ArrowRight as ArrowRightIcon } from 'react-feather';

import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import keys from 'lodash.keys';

import { settingsSet } from '../store/settings_slice';

import { renderOverlay, renderError, renderOffline } from '../components/render_helpers';
import Confirm from '../components/confirm';
import ClaimShowDetail from '../components/claim_show/detail';
import ClaimShowBuilderReport from '../components/claim_show/builder_report';
import ClaimShowClaimItemList from '../components/claim_show/claim_item_list';
import ClaimShowClaimImageList from '../components/claim_show/claim_image_list';
import InputField from '../components/form/input_field';

import {
  claimShowPageQuery,
  claimBuilderReportFormPageQuery,
  claimSignedUrlQuery,
  claimReset as claimResetMutation,
  claimSubmit as claimSubmitMutation,
  claimRiStatusUpdate as claimRiStatusUpdateMutation,
} from '../graphql/claim_queries';
import { claimClaimImageListPageQuery } from '../graphql/claim_claim_image_queries';

import { toastSuccess, toastWarning, toastError } from '../lib/toast_helpers';
import { handleSubmitError } from '../lib/utils';
import * as updateFunctions from '../update_functions';
import { builderReportFormValidator } from '../validators';

const ClaimShow = () => {
  const [modalErrors, setModalErrors] = useState([]);
  const [modalImageId, setModalImageId] = useState(false);
  const [modalId, setModalId] = useState(false);
  const [modalRiStatusUpdate, setModalRiStatusUpdate] = useState({
    show: false,
    claimId: null,
    riStatus: '',
  });
  const [signedUrlModal, setSignedUrlModal] = useState({
    show: false,
    signedUrl: '',
    isUrlCopied: false,
  });

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user);
  const params = useParams();
  const settingsTenant = useSelector((state) => state.settings.tenant);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const settingsTenantHasQccInspections = useSelector(
    (state) => state.settings.tenantHasQccInspections
  );
  const settingsTenantHasReinspections = useSelector(
    (state) => state.settings.tenantHasReinspections
  );

  const [claimSubmit] = useMutation(claimSubmitMutation);
  const [claimReset] = useMutation(claimResetMutation);
  const [claimRiStatusUpdate] = useMutation(claimRiStatusUpdateMutation);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    networkStatus: pageNetworkStatus,
    client: pageClient,
  } = useQuery(claimShowPageQuery, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first',
    variables: { claimId: params.id },
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const [claimSignedUrl] = useLazyQuery(claimSignedUrlQuery);

  const claim = useMemo(() => get(pageData, ['claim']), [pageData]);

  const isReinspection = useMemo(() => {
    if (claim && (claim.firstReinspection || claim.secondReinspection)) {
      return true;
    }
    return false;
  }, [claim]);

  const isQccInspection = useMemo(() => {
    if (claim && claim.qccInspections && claim.qccInspections.length > 0) {
      return true;
    }
    return false;
  }, [claim]);

  const isInitialInspection = useMemo(() => {
    if (claim && !isReinspection && !isQccInspection) {
      return true;
    }
    return false;
  }, [claim, isReinspection, isQccInspection]);

  const riStatuses = useMemo(
    () => get(pageData, ['enums', 'enums', 'RiStatuses'], []),
    [pageData]
  );

  const claimResetClicked = async (e) => {
    const dataId = e.currentTarget.getAttribute('data-id');
    const claimId = uuidValidate(dataId)
      ? dataId
      : parseInt(e.currentTarget.getAttribute('data-id'), 10);
    const mutationData = {
      variables: { id: claimId },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'ClaimType',
        recordId: claimId,
        mutationType: 'UPDATE',
      },
      update: updateFunctions.claimReset,
    };
    mutationData.optimisticResponse = updateFunctions.optimistic(
      'claimReset',
      mutationData,
      {
        riStatus: riStatuses.RESETTING,
      }
    );
    const mutationMessageAction = 'update';
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await claimReset(mutationData);
        toastSuccess(`Claim Reset ${mutationMessageAction} succeeded`);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
    } else {
      claimReset(mutationData);
      toastWarning(
        `Claim Reset ${mutationMessageAction} ok locally. Go online to make permanent`
      );
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
    }
    return undefined;
  };

  const showRiStatusUpdateModal = ({ id: claimId, riStatus }) => {
    setModalRiStatusUpdate({ show: true, claimId, riStatus });
  };

  const hideRiStatusUpdateModal = () => {
    setModalRiStatusUpdate({
      show: false,
      claimId: null,
      riStatus: '',
    });
  };

  const handleRiStatusUpdateModalChange = (e) => {
    setModalRiStatusUpdate((prevState) => ({
      ...prevState,
      riStatus: e.target.value,
    }));
  };

  const claimSubmitComplete = async (claimId) => {
    const mutationData = {
      variables: { id: claimId },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'ClaimType',
        recordId: claimId,
        mutationType: 'UPDATE',
      },
      update: updateFunctions.claimSubmit,
    };
    mutationData.optimisticResponse = updateFunctions.optimistic(
      'claimSubmit',
      mutationData,
      {
        riStatus: riStatuses.SUBMITTING,
      }
    );
    const mutationMessageAction = 'update';
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await claimSubmit(mutationData);
        toastSuccess(`Claim Status ${mutationMessageAction} succeeded`);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
    } else {
      claimSubmit(mutationData);
      toastWarning(
        `Claim Status ${mutationMessageAction} ok locally. Go online to make permanent`
      );
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
    }
    return undefined;
  };

  const claimSubmitReinspection = (e) => {
    const dataId = e.currentTarget.getAttribute('data-id');
    const claimId = uuidValidate(dataId)
      ? dataId
      : parseInt(e.currentTarget.getAttribute('data-id'), 10);
    claimSubmitComplete(claimId);
  };

  const claimSubmitQccInspection = (e) => {
    const dataId = e.currentTarget.getAttribute('data-id');
    const claimId = uuidValidate(dataId)
      ? dataId
      : parseInt(e.currentTarget.getAttribute('data-id'), 10);
    claimSubmitComplete(claimId);
  };

  const showErrorModal = (claimId, errors) => {
    const errorKeys = keys(errors);
    const formattedErrors = errorKeys.map((k) => capitalCase(k));
    setModalErrors(formattedErrors);
    setModalId(claimId);
  };

  const hideErrorModal = () => {
    setModalErrors([]);
    setModalId(false);
  };

  const showErrorImageModal = (claimId) => {
    setModalImageId(claimId);
  };

  const hideErrorImageModal = () => {
    setModalImageId(false);
  };

  const continueErrorImageModal = () => {
    const claimId = modalImageId;
    setModalImageId(false);
    // claimSubmitCheckItems(claimId);
    claimSubmitComplete(claimId);
  };

  const claimSubmitCheckImages = (claimId) => {
    pageClient
      .query({
        query: claimClaimImageListPageQuery,
        variables: { claimId: claimId.toString() },
        fetchPolicy: 'cache-only',
      })
      .then((resp) => {
        const values = get(resp, 'data.claim.claimImages');
        if (values) {
          if (isEmpty(values)) {
            if (window.$NODE_ENV !== 'development') {
              window.Rollbar.info('Zero claimsImages in submit check', { claimId });
            }
            showErrorImageModal(claimId);
          } else {
            // claimSubmitCheckItems(claimId);
            claimSubmitComplete(claimId);
          }
        } else if (window.$NODE_ENV !== 'development') {
          window.Rollbar.info('Missing claimsImages for submit check', { claimId });
        }
      });
  };

  const claimSubmitCheckBuilderReport = (e) => {
    const dataId = e.currentTarget.getAttribute('data-id');
    const claimId = uuidValidate(dataId)
      ? dataId
      : parseInt(e.currentTarget.getAttribute('data-id'), 10);
    pageClient
      .query({
        query: claimBuilderReportFormPageQuery,
        variables: { claimId: claimId.toString() },
        fetchPolicy: 'cache-only',
      })
      .then((resp) => {
        const values = get(resp, 'data.claim.builderReport');
        if (values) {
          const errors = builderReportFormValidator(values);
          if (isEmpty(errors)) {
            claimSubmitCheckImages(claimId);
          } else {
            showErrorModal(claimId, errors);
          }
        } else if (window.$NODE_ENV !== 'development') {
          window.Rollbar.info('Missing builders report data for submit check', {
            claimId,
          });
        }
      });
  };

  const submitRiStatusUpdate = async ({ claimId, riStatus }) => {
    const mutationData = {
      variables: { id: claimId, riStatus },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'ClaimType',
        recordId: claimId,
        mutationType: 'UPDATE',
      },
      update: updateFunctions.claimRiStatusUpdate,
    };
    mutationData.optimisticResponse = updateFunctions.optimistic(
      'claimRiStatusUpdate',
      mutationData,
      {
        riStatus,
      }
    );
    const mutationMessageAction = 'update';
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await claimRiStatusUpdate(mutationData);
        toastSuccess(`Claim Status ${mutationMessageAction} succeeded`);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        hideRiStatusUpdateModal();
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
    } else {
      claimRiStatusUpdate(mutationData);
      toastWarning(
        `Claim Status ${mutationMessageAction} ok locally. Go online to make permanent`
      );
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      hideRiStatusUpdateModal();
    }
    return undefined;
  };

  const showSignedUrlModal = async ({ riJobNumber }) => {
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        const claimSignedUrlResp = await claimSignedUrl({
          variables: {
            riJobNumber,
          },
        });
        toastSuccess(`Signed URL Created`);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        const {
          data: {
            claimSignedUrl: { signedUrl },
          },
        } = claimSignedUrlResp;
        setSignedUrlModal((prevState) => ({ ...prevState, signedUrl, show: true }));
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
    } else {
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      setSignedUrlModal((prevState) => ({
        ...prevState,
        signedUrl: 'Go Online to retrieve updated link',
        show: true,
      }));
    }
    return undefined;
  };

  const hideSignedUrlModal = () => {
    setSignedUrlModal((prevState) => ({
      ...prevState,
      show: false,
      isUrlCopied: false,
    }));
  };

  const copySignedUrl = async (url) => {
    try {
      await navigator.clipboard.writeText(url);
      return setSignedUrlModal((prevState) => ({
        ...prevState,
        isUrlCopied: true,
      }));
    } catch (e) {
      return e;
    }
  };

  const isEditingEnabled = () => {
    // if (isReinspection) {
    //   return claim.riFollowUpStatus === riStatuses.REINSPECTION;
    // }
    // if (isQccInspection) {
    //   return claim.riFollowUpStatus === riStatuses.QCC_INSPECTION;
    // }
    // if (isInitialInspection) {
    //   return claim.riStatus === riStatuses.READY || claim.riStatus === riStatuses.OVERDUE;
    // }
    let editableStatuses = [
      riStatuses.READY,
      riStatuses.OVERDUE,
      riStatuses.A,
      riStatuses.I,
      riStatuses.Q,
    ];
    // this is a mess and a hack
    if (isQccInspection) {
      editableStatuses = [...editableStatuses, riStatuses.C];
    }
    return editableStatuses.includes(claim.riStatus);
  };

  const renderSubmitJobButton = (confirmName) => {
    if (
      claim.riStatus === riStatuses.SUBMITTING ||
      claim.riStatus === riStatuses.FAILED
    ) {
      return (
        <Button variant="danger" disabled>
          {`submitting ${confirmName}`}
        </Button>
      );
    }
    if (claim.riStatus === riStatuses.INSPECTED) {
      return (
        <Button variant="success" disabled>
          submitted
        </Button>
      );
    }
    if (isEditingEnabled()) {
      return <Button variant="danger">{`submit ${confirmName}`}</Button>;
    }
    return (
      <Button variant="light" disabled>
        disabled
      </Button>
    );
  };

  const renderSubmitButton = () => {
    let confirmName = '';
    let confirmText = [];
    let onConfirm = () => {};
    if (isInitialInspection) {
      confirmName = 'job';
      confirmText = [
        "This will lodge the builder's report, work schedule and site images with ClaimsDesk",
        'You will not be able to make further changes',
      ];
      onConfirm = claimSubmitCheckBuilderReport;
    }
    if (isReinspection) {
      confirmName = 'reinspection';
      confirmText = [
        'This will lodge the reinspection report, new work schedule and site image items with ClaimsDesk',
        'You will not be able to make further changes',
      ];
      onConfirm = claimSubmitReinspection;
    }
    if (isQccInspection) {
      confirmName = 'qcc inspection';
      confirmText = [
        'This will lodge the QCC inspection report with ClaimsDesk',
        'You will not be able to make further changes',
      ];
      onConfirm = claimSubmitQccInspection;
    }
    return (
      <Confirm
        dataId={claim.id}
        onConfirm={onConfirm}
        title={`Submit ${confirmName}`}
        body={confirmText}
      >
        {renderSubmitJobButton(confirmName)}
      </Confirm>
    );
  };

  const renderLists = () => (
    <>
      <LinkContainer to="/inspected">
        <Button variant="primary">Inspected</Button>
      </LinkContainer>
      {settingsTenantHasReinspections && (
        <LinkContainer to="/reinspections">
          <Button variant="primary">Reinspections</Button>
        </LinkContainer>
      )}
      {settingsTenantHasQccInspections && (
        <LinkContainer to="/qcc_inspections">
          <Button variant="primary">QCC Inspections</Button>
        </LinkContainer>
      )}
      <LinkContainer to="/claims">
        <Button variant="primary">All Claims</Button>
      </LinkContainer>
    </>
  );

  const renderTools = () => (
    <>
      {isInitialInspection && (
        <Button variant="primary" disabled={!isEditingEnabled()}>
          <LinkContainer to={`/claims/${claim.id}/builder_report/edit`}>
            <span>builder&#39;s report</span>
          </LinkContainer>
        </Button>
      )}
      {claim.firstReinspection && !claim.secondReinspection && (
        <>
          <LinkContainer to={`/claims/${claim.id}/first_reinspection/edit`}>
            <Button variant="primary" disabled={!isEditingEnabled()}>
              reinspection report
            </Button>
          </LinkContainer>
          <br />
        </>
      )}
      {claim.secondReinspection && (
        <>
          <LinkContainer to={`/claims/${claim.id}/second_reinspection/edit`}>
            <Button variant="primary" disabled={!isEditingEnabled()}>
              reinspection report
            </Button>
          </LinkContainer>
          <br />
        </>
      )}

      {isQccInspection && (
        <Button variant="primary" disabled={!isEditingEnabled()}>
          <LinkContainer to={`/claims/${claim.id}/qcc_inspection/edit`}>
            <span>qcc inspection report</span>
          </LinkContainer>
        </Button>
      )}

      <Button variant="primary" disabled={!isEditingEnabled()}>
        <LinkContainer to={`/claims/${claim.id}/claim_images`}>
          <span>images</span>
        </LinkContainer>
      </Button>

      {isQccInspection || (
        <>
          <Button variant="primary" disabled={!isEditingEnabled()}>
            <LinkContainer to={`/claims/${claim.id}/internal_notes`}>
              <span>internal notes</span>
            </LinkContainer>
          </Button>

          <Button variant="primary" disabled={!isEditingEnabled()}>
            <LinkContainer to={`/claims/${claim.id}/claim_items`}>
              <span> schedule of work</span>
            </LinkContainer>
          </Button>
        </>
      )}
    </>
  );

  const renderAdminTools = () => (
    <>
      {currentUser.admin && (
        <Button variant="outline-danger" onClick={() => showRiStatusUpdateModal(claim)}>
          update status
        </Button>
      )}
      {currentUser.admin && (
        <Button
          variant="outline-primary"
          onClick={() => showSignedUrlModal(claim)}
          disabled={!claim.submittedAt}
        >
          download link
        </Button>
      )}
      {currentUser.admin && (
        <Confirm
          dataId={claim.id}
          onConfirm={claimResetClicked}
          title="Reset Job"
          body={[
            'WARNING!',
            "This will ONLY reset the Claim to 'R' (requested) in the remote app and allow new updates and resubmission.",
            'Before resubmitting, you MUST ensure the claim is manually reset to Requested in ClaimsDesk and any imported images and job notes are removed.',
            'Otherwise they might be duplicated.',
          ]}
        >
          <Button variant="outline-danger" disabled={isEditingEnabled(claim)}>
            reset to requested
          </Button>
        </Confirm>
      )}
    </>
  );

  const renderModals = () => (
    <>
      <Modal show={!!modalId} onHide={hideErrorModal} centered>
        <Modal.Header>
          <Modal.Title>
            {`Fix ${modalErrors.length} Error${modalErrors.length > 1 ? 's' : ''}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            The following builder report fields have missing or incorrect information.
            Check all tabs.
            <br />
            You will not be able to submit the claim until all errors are resolved
          </p>
          {modalErrors.map((modalError) => (
            <p key={modalError}>
              <ArrowRightIcon className="feather-sm me-3" />
              {modalError}
            </p>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <LinkContainer to={`/claims/${modalId}/builder_report/edit`}>
            <Button type="button" variant="danger">
              Open Builder&apos;s Report
            </Button>
          </LinkContainer>
          <Button type="button" variant="primary" onClick={hideErrorModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={!!modalImageId} onHide={hideErrorImageModal} centered>
        <Modal.Header>
          <Modal.Title>Confirm Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            There are no images saved for this Claim. Check you have both uploaded and
            saved the images.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="danger" onClick={continueErrorImageModal}>
            Confirm Images
          </Button>
          <LinkContainer to={`/claims/${modalImageId}/claim_images`}>
            <Button type="button" variant="primary">
              Open Claim Images
            </Button>
          </LinkContainer>
          <Button type="button" variant="primary" onClick={hideErrorImageModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

  const renderAdminModals = () => (
    <>
      <Modal show={!!modalRiStatusUpdate.show} onHide={hideRiStatusUpdateModal} centered>
        <Modal.Header>
          <Modal.Title>Update Job Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Updating job status may have unintended consequences. Make sure you know what
            you are doing.
          </p>
          <InputField
            name="riStatus"
            labelWidth={3}
            inputWidth={6}
            asElement="select"
            defaultSelectOption={false}
            selectOptions={Object.entries(riStatuses).map(
              ([riStatusKey, riStatusValue]) => ({
                id: riStatusValue,
                name: capitalCase(riStatusKey),
              })
            )}
            input={{
              name: 'ri-status-update-modal-ri-status',
              value: modalRiStatusUpdate.riStatus,
              onChange: handleRiStatusUpdateModalChange,
            }}
            meta={{}}
          >
            Job status
          </InputField>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="light" onClick={hideRiStatusUpdateModal}>
            Close
          </Button>
          <Button
            type="button"
            variant="danger"
            onClick={() => submitRiStatusUpdate(modalRiStatusUpdate)}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={!!signedUrlModal.show} onHide={hideSignedUrlModal} centered>
        <Modal.Header>
          <Modal.Title>Copy Download Url</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Copy and paste this url into the browser to download the zip file.</p>
          <pre>{signedUrlModal.signedUrl}</pre>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="light" onClick={hideSignedUrlModal}>
            Close
          </Button>
          <Button
            type="button"
            variant="primary"
            onClick={() => copySignedUrl(signedUrlModal.signedUrl)}
          >
            {signedUrlModal.isUrlCopied ? 'Copied!' : 'Copy Download Url'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

  const renderContent = () => {
    const { id: claimId, builderReport, claimItems, claimImages } = claim;
    return (
      <>
        {renderModals()}
        {renderAdminModals()}
        <Row className="mt-4 mb-3">
          <Col sm={12}>
            <div className="float-none">
              <div className="float-start">
                <h1 className="h3 mb-3">Claim Detail</h1>
              </div>
              <div className="float-end">
                <ButtonGroup className="me-2">{renderAdminTools()}</ButtonGroup>
                <ButtonGroup className="me-2">{renderSubmitButton()}</ButtonGroup>
                <ButtonGroup className="me-2">{renderTools()}</ButtonGroup>
                <ButtonGroup>{renderLists()}</ButtonGroup>
              </div>
            </div>
          </Col>
        </Row>
        <ClaimShowDetail claim={claim} />
        {builderReport && <ClaimShowBuilderReport builderReport={builderReport} />}
        {claimImages && (
          <ClaimShowClaimImageList claimId={claimId} claimImages={claimImages} />
        )}
        {claimItems && <ClaimShowClaimItemList claimItems={claimItems} />}
      </>
    );
  };

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default ClaimShow;
