import {
  Modal,
  Form,
  Button,
  Row,
  Col,
  ButtonToolbar,
  ButtonGroup,
} from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import arrify from 'arrify';

import curry from 'lodash.curry';
import clone from 'lodash.clone';
import get from 'lodash.get';
import first from 'lodash.first';
import map from 'lodash.map';
import merge from 'lodash.merge';
import pick from 'lodash.pick';
import pickBy from 'lodash.pickby';
import round from 'lodash.round';
import sum from 'lodash.sum';
import values from 'lodash.values';
import SubmitButtonSpinner from '../components/submit_button_spinner';
import Field from '../components/form/rff_field';
import OnChangeField from '../components/form/rff_on_change_field';
import InputField from '../components/form/input_field';
import InvalidBlock from '../components/form/invalid_block';
import LabelBlock from '../components/form/label_block';
import FocusModalInputField from '../components/form/focus_modal_input_field';

import { pickValues } from '../lib/utils';
import { claimItemFormValidator } from '../validators';

import {
  claimItemWhiteList,
  claimItemPickList,
  oacItemPickList,
  oacItemPricingPickList,
} from '../white_lists/claim_item_form_white_lists';

const ClaimItemForm = ({
  open,
  selectedFloor,
  selectedRoom,
  lastClaimItem,
  oacCategoryList,
  enums,
  claimItem,
  handleSetModalToggle,
  handleClaimItemSubmit,
  oacMinChargeAccumulator,
}) => {
  let clickedSubmitButton = 'new';

  // eslint-disable-next-line arrow-body-style
  const getInitialValues = () => {
    return {
      ...{ itemFloor: selectedFloor },
      ...{ itemRoom: selectedRoom },
      ...(lastClaimItem && pickValues(lastClaimItem, ['oacCategoryId'])),
      ...pickValues(claimItem, claimItemWhiteList),
    };
  };

  const onHide = () => handleSetModalToggle && handleSetModalToggle();
  const onCancel = () => handleSetModalToggle && handleSetModalToggle();

  const getSelectedOacCategoryId = (oacCategoryId) => {
    if (oacCategoryId) {
      return [
        pick(
          oacCategoryList.find((ic) => ic.id === parseInt(oacCategoryId, 10)),
          ['id', 'name']
        ),
      ];
    }
    return [];
  };

  const getOacItems = (oacCategoryId) => {
    if (oacCategoryId) {
      return oacCategoryList
        .find((ic) => ic.id === parseInt(oacCategoryId, 10))
        .oacItems.map(({ id, itemDescription }) => ({ id, name: itemDescription }));
    }
    return [];
  };

  const getClaimItemMinChargeAccumulator = (oacItemId) => {
    if (!oacItemId) {
      return 0;
    }
    const claimItems = get(oacMinChargeAccumulator, [parseInt(oacItemId, 10)], {});
    return sum(
      map(
        values(pickBy(claimItems, (_, key) => parseInt(key, 10) !== claimItem.id)),
        first
      )
    );
  };

  const getTotalClaimItemMinChargeAccumulator = ({
    oacItemId,
    internalMinimumChargeQuantity = 0,
  }) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    round(
      getClaimItemMinChargeAccumulator(oacItemId) +
        (Number.isNaN(parseFloat(internalMinimumChargeQuantity))
          ? 0
          : parseFloat(internalMinimumChargeQuantity)),
      2
    );

  const validateInternalMinimumChargeQuantity = (
    value = 0,
    { oacItemId, minimumChargeQuantity, itemUnitOfMeasure }
  ) => {
    if (itemUnitOfMeasure === 'Min Charge') {
      const currentValue = getClaimItemMinChargeAccumulator(oacItemId);
      if (round(currentValue + parseFloat(value), 2) > minimumChargeQuantity) {
        return [
          `Minimum Charge Quantity cannot be higher than ${round(
            minimumChargeQuantity - currentValue,
            2
          )} `,
        ];
      }
      if (Number.isNaN(parseFloat(value)) || value === 0) {
        return ['should have required property "internalMinimumChargeQuantity"'];
      }
    }
    return undefined;
  };

  const onFormSubmit = (data, form) => {
    const submitData = clone(data);
    submitData.itemTrade = get(
      oacCategoryList.find((ic) => ic.id === parseInt(submitData.oacCategoryId, 10)),
      ['name']
    );
    handleClaimItemSubmit(submitData, clickedSubmitButton);
    if (clickedSubmitButton === 'new') {
      form.restart(pick(submitData, ['itemFloor', 'itemRoom', 'oacCategoryId']));
    }
  };

  const onOacCategoryIdChange = (form) => {
    form.batch(() => {
      form.change('oacItemId', '');
      form.change('oacItemPricingId', '');
      form.change('itemDescription', '');
    });
  };

  const onOacItemIdChange = (form, values) => {
    const { oacCategoryId, oacItemId } = values;
    let newValues = {};
    if (oacCategoryId && oacItemId) {
      const oacItem = oacCategoryList
        .find((oic) => oic.id === parseInt(oacCategoryId, 10))
        .oacItems.find((oi) => oi.id === parseInt(oacItemId, 10));
      newValues = merge(pick(values, claimItemPickList), pick(oacItem, oacItemPickList));
      newValues.itemQuantity = '';
      if (!oacItem.itemCustom) {
        const oacItemPricing = get(oacItem, ['oacItemPricings', '0']);
        newValues = merge(newValues, pick(oacItemPricing, oacItemPricingPickList));
        newValues.oacItemPricingId = oacItemPricing.id;
        if (oacItemPricing.itemPricingFixed) {
          if (oacItem.itemUnitOfMeasure === 'Min Charge') {
            const currentMinChargeItems = getClaimItemMinChargeAccumulator(oacItem.id);
            if (currentMinChargeItems > 0) {
              newValues.itemQuantity = 0;
            } else {
              newValues.itemQuantity = 1;
            }
          } else {
            newValues.itemQuantity = 1;
          }
        }
      }
    } else {
      newValues = merge(pick(values, claimItemPickList));
      newValues.oacItemPricingId = '';
      newValues.itemQuantity = '';
    }
    newValues.internalItemQuantity = '';
    newValues.internalMinimumChargeQuantity = '';
    form.reset(newValues);
  };

  const onFloatBlur = curry((form, name, onBlur, e) => {
    const value = Number.isNaN(parseFloat(e.target.value))
      ? ''
      : round(parseFloat(e.target.value), 2);
    form.change(name, value);
    onBlur(e);
  });

  const onTypeaheadBlur = curry((form, name, e) => {
    e.preventDefault();
    form.mutators.setFieldTouched(name, true);
  });

  const onTypeaheadChange = curry((form, name, value) => {
    let formValue = value[0] || null;
    if (formValue && formValue.id) {
      formValue = formValue.id;
    }
    form.change(name, formValue);
  });

  const renderContent = () => (
    <>
      <Modal.Header>
        <Modal.Title>{claimItem.id ? 'Update Item' : 'Create Item'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FinalForm
          initialValues={getInitialValues()}
          onSubmit={onFormSubmit}
          validate={claimItemFormValidator}
          mutators={{ setFieldTouched }}
        >
          {({ handleSubmit, pristine, form, submitting, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Field name="itemRoom">{() => null}</Field>
              <Field name="oacCategoryId">{() => null}</Field>
              <OnChangeField name="oacCategoryId">
                {() => onOacCategoryIdChange(form)}
              </OnChangeField>
              <OnChangeField name="oacItemId">
                {() => onOacItemIdChange(form, values)}
              </OnChangeField>
              <Row className="mb-3">
                <Field
                  name="itemFloor"
                  component={InputField}
                  asElement="select"
                  selectOptions={enums.ItemFloors.map((floor) => ({
                    id: floor,
                    name: floor,
                  }))}
                  defaultSelectOptionName="select floor..."
                  labelWidth={0}
                  inputWidth={4}
                  size="md"
                >
                  Floor
                </Field>
                <InputField
                  size="md"
                  labelWidth={0}
                  inputWidth={4}
                  input={{
                    name: 'itemRoom',
                  }}
                  innerContent={
                    <>
                      <LabelBlock size="md">Room</LabelBlock>
                      <Typeahead
                        id="itemroom-typeahead-single"
                        labelKey="itemRoom"
                        onBlur={onTypeaheadBlur(form, 'itemRoom')}
                        onChange={onTypeaheadChange(form, 'itemRoom')}
                        options={enums.ItemRooms}
                        placeholder="select room..."
                        selected={arrify(values.itemRoom)}
                      />
                      <InvalidBlock meta={form.getFieldState('itemRoom') || {}} force />
                    </>
                  }
                />
                <InputField
                  size="md"
                  labelWidth={0}
                  inputWidth={4}
                  input={{
                    name: 'oacCategoryId',
                  }}
                  innerContent={
                    <>
                      <LabelBlock size="md">OAC Category</LabelBlock>
                      <Typeahead
                        id="oac-category-typeahead-single"
                        labelKey="name"
                        onBlur={onTypeaheadBlur(form, 'oacCategoryId')}
                        onChange={onTypeaheadChange(form, 'oacCategoryId')}
                        options={oacCategoryList.map(({ id, name }) => ({ id, name }))}
                        placeholder="select oac category..."
                        selected={getSelectedOacCategoryId(values.oacCategoryId)}
                      />
                      <InvalidBlock
                        meta={form.getFieldState('oacCategoryId') || {}}
                        force
                      />
                    </>
                  }
                />
              </Row>
              <Field
                name="oacItemId"
                component={InputField}
                asElement="select"
                selectOptions={getOacItems(values.oacCategoryId)}
                defaultSelectOptionName="select item..."
                size="md"
              >
                OAC Item
              </Field>
              <hr />
              <Field
                name="itemDescription"
                component={FocusModalInputField}
                disabled={!!(values.oacItemId && !values.itemCustom)}
                asElement="textarea"
                form={form}
                rows={2}
                size="md"
              >
                Description
              </Field>
              {values.itemNotes && (
                <InputField
                  input={{
                    name: 'itemNotes',
                    value: values.ItemNotes,
                  }}
                  meta={{}}
                  plainText
                  helpBlock={false}
                  type="text"
                  size="md"
                  noTab
                >
                  Notes
                </InputField>
              )}
              {values.itemValidationNotes && (
                <InputField
                  input={{
                    name: 'itemNotes',
                    value: values.itemValidationNotes,
                  }}
                  meta={{}}
                  plainText
                  helpBlock={false}
                  type="text"
                  size="md"
                  noTab
                >
                  Validation Notes
                </InputField>
              )}
              {values.itemMinimumChargeNotes && (
                <InputField
                  input={{
                    name: 'itemMinimumChargeNotes',
                    value: values.itemMinimumChargeNotes,
                  }}
                  meta={{}}
                  plainText
                  helpBlock={false}
                  type="text"
                  size="md"
                  noTab
                >
                  Min Charge Notes
                </InputField>
              )}

              {values.oacItemId && (
                <>
                  <hr />
                  <Row className="mb-3">
                    <Field
                      name="itemQuantity"
                      component={InputField}
                      disabled={values.itemPricingFixed}
                      customOnBlur={onFloatBlur(form)}
                      labelWidth={0}
                      inputWidth={4}
                    >
                      Quantity
                    </Field>
                    <Field
                      name="itemUnitOfMeasure"
                      component={InputField}
                      asElement="select"
                      selectOptions={enums.ItemUnitOfMeasures.map((unit) => ({
                        id: unit,
                        name: unit,
                      }))}
                      defaultSelectOptionName="select unit of measure..."
                      disabled={!values.itemCustom}
                      labelWidth={0}
                      inputWidth={4}
                    >
                      Unit of Measure
                    </Field>
                    <Field
                      name="itemPricingUnitPrice"
                      component={InputField}
                      disabled={!values.itemCustom}
                      prepend="$"
                      labelWidth={0}
                      inputWidth={4}
                    >
                      Unit Price
                    </Field>
                  </Row>
                </>
              )}
              {values.itemUnitOfMeasure === 'Min Charge' && (
                <Row className="mb-3">
                  <Field
                    name="internalMinimumChargeQuantity"
                    component={InputField}
                    validate={validateInternalMinimumChargeQuantity}
                    customOnBlur={onFloatBlur(form)}
                    labelWidth={0}
                    inputWidth={0}
                  >
                    {`This Min Charge (${values.minimumChargeUnitOfMeasure})`}
                  </Field>

                  <InputField
                    input={{
                      name: 'usedMinimumChargeQuantity',
                      value: getClaimItemMinChargeAccumulator(values.workCenterItemId),
                    }}
                    meta={{}}
                    plainText
                    helpBlock={false}
                    type="text"
                    noTab
                    labelWidth={0}
                    inputWidth={0}
                  >
                    Other Min Charge
                  </InputField>
                  <InputField
                    input={{
                      name: 'totalMinimumChargeQuantity',
                      value: getTotalClaimItemMinChargeAccumulator(values),
                    }}
                    meta={{}}
                    plainText
                    helpBlock={false}
                    type="text"
                    noTab
                    labelWidth={0}
                    inputWidth={0}
                  >
                    Total Min Charge
                  </InputField>
                  <InputField
                    input={{
                      name: 'minimumChargeQuantity',
                      value: values.minimumChargeQuantity,
                    }}
                    meta={{}}
                    plainText
                    helpBlock={false}
                    type="text"
                    noTab
                    labelWidth={0}
                    inputWidth={0}
                  >
                    Allowed Min Charge
                  </InputField>
                  <InputField
                    input={{
                      name: 'minimumChargeUnitOfMeasure',
                      value: values.minimumChargeUnitOfMeasure,
                    }}
                    meta={{}}
                    plainText
                    helpBlock={false}
                    type="text"
                    noTab
                    labelWidth={0}
                    inputWidth={0}
                  >
                    Min Charge UofM
                  </InputField>
                </Row>
              )}
              <hr />
              <Form.Group as={Row}>
                <Col sm={12}>
                  <ButtonToolbar style={{ justifyContent: 'flex-end' }}>
                    <ButtonGroup className="me-2">
                      <Button variant="danger" onClick={onCancel} disabled={submitting}>
                        Cancel
                      </Button>
                    </ButtonGroup>
                    <ButtonGroup className="me-2">
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={pristine || submitting}
                        onClick={(event) => {
                          clickedSubmitButton = 'new';
                          handleSubmit(event);
                        }}
                      >
                        {submitting && <SubmitButtonSpinner />}
                        {claimItem.id ? 'Update and New' : 'Create and New'}
                      </Button>
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={pristine || submitting}
                        onClick={(event) => {
                          clickedSubmitButton = 'close';
                          handleSubmit(event);
                        }}
                      >
                        {submitting && <SubmitButtonSpinner />}
                        {claimItem.id ? 'Update and Close' : 'Create and Close'}
                      </Button>
                    </ButtonGroup>
                  </ButtonToolbar>
                </Col>
              </Form.Group>
            </form>
          )}
        </FinalForm>
      </Modal.Body>
    </>
  );

  return (
    <div>
      <Modal show={open} onHide={onHide} size="lg" centered>
        {renderContent()}
      </Modal>
    </div>
  );
};

export default ClaimItemForm;
