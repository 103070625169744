import { qccInspectionQuery } from '../graphql/qcc_inspection_queries';
import cleanCache from './clean_cache';

export const qccInspectionUpdate = (
  cache,
  { data: { qccInspectionUpdate: updateData } }
) => {
  const data = { qccInspection: updateData };
  cache.writeQuery({
    query: qccInspectionQuery,
    variables: { id: updateData.id.toString() },
    data,
  });
  cleanCache(cache);
};
