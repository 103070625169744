export function isInvalid(meta) {
  return meta.touched && meta.invalid;
}

export function validationState(field) {
  return field.touched && field.invalid;
}

export function validationStates(fields) {
  return fields.map((field) => validationState(field)).filter((field) => !field).length > 0;
}

export function validationText(field, helpText = '') {
  return field.touched && field.error && field.error !== '' ? field.error.join(', ') : helpText;
}
