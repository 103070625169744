export const qccInspectionIntegers = [];
export const qccInspectionFloats = [];
export const qccInspectionIsoStrings = ['inspectionDate'];
// these are allowed in forms
export const qccInspectionWhiteList = [
  'inspectorNotes',
  'clientNotes',
  'failedQcc',
  'stageOfJobCompletion',
  'metClientOnSite',
  'anotherVisitRequired',
  'interiorCarpentry',
  'interiorCarpentryComments',
  'interiorGibRepairs',
  'interiorGibRepairsComments',
  'interiorDecorating',
  'interiorDecoratingComments',
  'interiorPlumbing',
  'interiorPlumbingComments',
  'interiorElectricalAlarmsHeatpump',
  'interiorElectricalAlarmsHeatpumpComments',
  'interiorFlooring',
  'interiorFlooringComments',
  'interiorCabinetmakerBenchtops',
  'interiorCabinetmakerBenchtopsComments',
  'interiorTiling',
  'interiorTilingComments',
  'interiorOther1',
  'interiorOther1Comments',
  'interiorOther2',
  'interiorOther2Comments',
  'interiorOther3',
  'interiorOther3Comments',
  'interiorWorkMeetsQualityStandardsAndSpecifications',
  'interiorWorkMeetsQualityStandardsAndSpecificationsComments',
  'interiorScopeCompletedInFull',
  'interiorScopeCompletedInFullComments',
  'interiorRepairAreaLeftCleanAndTidy',
  'interiorRepairAreaLeftCleanAndTidyComments',
  'exteriorCarpentry',
  'exteriorCarpentryComments',
  'exteriorPlastering',
  'exteriorPlasteringComments',
  'exteriorPainting',
  'exteriorPaintingComments',
  'exteriorPlumbing',
  'exteriorPlumbingComments',
  'exteriorRoofing',
  'exteriorRoofingComments',
  'exteriorSpoutingGuttering',
  'exteriorSpoutingGutteringComments',
  'exteriorBrickBlockwork',
  'exteriorBrickBlockworkComments',
  'exteriorConcreteWorks',
  'exteriorConcreteWorksComments',
  'exteriorTimberAluminiumJoinery',
  'exteriorTimberAluminiumJoineryComments',
  'exteriorGarageDoors',
  'exteriorGarageDoorsComments',
  'exteriorOther1',
  'exteriorOther1Comments',
  'exteriorOther2',
  'exteriorOther2Comments',
  'exteriorOther3',
  'exteriorOther3Comments',
  'exteriorWorkMeetsQualityStandardsAndSpecifications',
  'exteriorWorkMeetsQualityStandardsAndSpecificationsComments',
  'exteriorScopeCompletedInFull',
  'exteriorScopeCompletedInFullComments',
  'exteriorRepairAreaLeftCleanAndTidy',
  'exteriorRepairAreaLeftCleanAndTidyComments',
];
// these are also needed for the optimistic update to save successfully
export const qccInspectionOptimisticList = [
  'inspectionDate',
  'inspectionRef',
  'notesForInspector',
  ...qccInspectionWhiteList,
];
