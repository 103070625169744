import { useMemo } from 'react';
import { Col, Row, Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { camelCase } from 'change-case';
import { useSelector, useDispatch } from 'react-redux';

import transform from 'lodash.transform';

import { renderOverlay, renderOffline, renderError } from '../components/render_helpers';
import Confirm from '../components/confirm';
import ReactTable from '../components/react_table/react_table';

import { coerceInput, handleSubmitError } from '../lib/utils';
import { toastSuccess, toastError } from '../lib/toast_helpers';
import { settingsSet } from '../store/settings_slice';

import { oacReferralListQuery } from '../graphql/oac_referral_queries';
import { claimCreate as claimCreateMutation } from '../graphql/claim_queries';

const OacReferralList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);

  const [claimCreate] = useMutation(claimCreateMutation);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    networkStatus: pageNetworkStatus,
  } = useQuery(oacReferralListQuery, { notifyOnNetworkStatusChange: true });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const handleCreateClaimClick = async (e) => {
    const oacReferralId = e.currentTarget.getAttribute('data-id');
    const oacReferral = pageData.oacReferralList.find(
      (or) => or.id === parseInt(oacReferralId, 10)
    );
    const submitData = {
      oacReferralId,
      oacReferralCreatedAt: oacReferral.createdAt,
      // riAssessor: 'IAG : Fast Track Online',
      // riStatus: 'Requested',
      // riJobNumber: '19692',
      ...transform(oacReferral.body, (result, value, key) => {
        // eslint-disable-next-line no-param-reassign
        result[camelCase(key)] = value;
        return result;
      }),
    };
    const mutationData = {
      variables: {
        input: coerceInput(submitData),
      },
    };
    try {
      dispatch(
        settingsSet({
          mutating: true,
        })
      );
      await claimCreate(mutationData);
      toastSuccess(`Claim create succeeded`);
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      navigate('/claims');
    } catch (err) {
      const { errorMessage, submitErrors } = handleSubmitError(err);
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      toastError(errorMessage);
      return submitErrors;
    }
    return undefined;
  };

  function renderJsonCellWithMaxLineLength(cell, maxLineLength) {
    const json = JSON.stringify(cell, undefined, 2);

    const lines = json.split('\n');

    const formattedLines = lines.map((line) => {
      if (line.length > maxLineLength) {
        const chunks = [];
        for (let i = 0; i < line.length; i += maxLineLength) {
          chunks.push(line.substring(i, i + maxLineLength));
        }
        return chunks.join('\n');
      }
      return line;
    });

    return <pre>{formattedLines.join('\n')}</pre>;
  }

  const renderActionsCell = ({ row: { original: row } }) => {
    if (row.claim) {
      return (
        <LinkContainer to={`/claims/${row.claim.id}/edit`}>
          <Button variant="link" size="small">
            edit claim
          </Button>
        </LinkContainer>
      );
    }
    return (
      <Confirm
        dataId={row.id}
        disabled={row.claim}
        onConfirm={handleCreateClaimClick}
        title="Create Claim"
        body="Are you sure you want to create a claim from this referral?"
      >
        <Button variant="link" size="xsmall" disabled={row.claim}>
          create claim
        </Button>
      </Confirm>
    );
  };

  const parentColumns = [
    {
      header: 'Id',
      accessorKey: 'id',
      enableColumnFilter: false,
    },
    {
      header: 'Detail',
      accessorKey: 'body',
      enableColumnFilter: false,
      cell: ({ cell }) => renderJsonCellWithMaxLineLength(cell.getValue(), 120),
    },
  ];

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm={12}>
          <div className="float-none">
            <div className="float-left">
              <h1 className="h3 mb-3">OAC Referrals</h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <ReactTable
                rootName="oacReferral"
                parentColumns={parentColumns}
                data={pageData.oacReferralList}
                doShow={false}
                doEdit={false}
                actionCell={renderActionsCell}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default OacReferralList;
