import clone from 'lodash.clone';

import claimClaimImagesFragment from '../fragments/claim_claim_images_fragment';
import cleanCache from './clean_cache';

export const claimClaimImageCreate = (
  cache,
  { data: { claimClaimImageCreate: createData } },
  { context: { recordId } }
) => {
  let currentData;
  try {
    currentData = cache.readFragment({
      id: `ClaimType-${createData.claimId}`,
      fragment: claimClaimImagesFragment,
      fragmentName: 'ClaimClaimImagesFragment',
    });
  } catch (err) {
    console.log(err);
    if (window.$NODE_ENV !== 'development') {
      window.Rollbar.info('Failed read fragment', { ...createData, err });
    }
    currentData = null;
  }
  if (currentData) {
    // sometimes the optimistic create obect is not cleared away and remains as part of the collection
    // this is a hack to clean that up. to duplicate, go offline, create an image then a builder report update.
    // go online and optimistic entry will still appear along with the new entry.
    // surprisingly it all works if only image updates are performed
    let currentClaimImages = clone(currentData.claimImages);
    if (createData.id !== recordId) {
      currentClaimImages = currentClaimImages.filter(
        (claimImage) => claimImage.id !== recordId
      );
    }
    const claimImages = [...currentClaimImages, createData];
    try {
      currentData = cache.writeFragment({
        id: `ClaimType-${createData.claimId}`, // The value of the to-do item's unique identifier
        fragment: claimClaimImagesFragment,
        fragmentName: 'ClaimClaimImagesFragment',
        data: { claimImages },
      });
    } catch (err) {
      console.log(err);
      if (window.$NODE_ENV !== 'development') {
        window.Rollbar.info('Failed write fragment', { ...createData, err });
      }
    }
  }
  cleanCache(cache);
};

export const claimClaimImageDelete = (
  cache,
  { data: { claimClaimImageDelete: deleteData } }
) => {
  let currentData;
  try {
    currentData = cache.readFragment({
      id: `ClaimType-${deleteData.claimId}`, // The value of the to-do item's unique identifier
      fragment: claimClaimImagesFragment,
      fragmentName: 'ClaimClaimImagesFragment',
    });
  } catch (err) {
    console.log(err);
    if (window.$NODE_ENV !== 'development') {
      window.Rollbar.info('Failed read fragment', { ...deleteData, err });
    }
    currentData = null;
  }
  if (currentData) {
    try {
      const claimImages = currentData.claimImages.filter(
        (image) => deleteData.id !== image.id
      );
      currentData = cache.writeFragment({
        id: `ClaimType-${deleteData.claimId}`, // The value of the to-do item's unique identifier
        fragment: claimClaimImagesFragment,
        fragmentName: 'ClaimClaimImagesFragment',
        data: { claimImages },
      });
    } catch (err) {
      console.log(err);
      if (window.$NODE_ENV !== 'development') {
        window.Rollbar.info('Failed write fragment', {
          ...deleteData,
          err,
        });
      }
    }
  }
  cleanCache(cache);
};
