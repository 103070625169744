// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body {
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.content {
  min-height: calc(100vh - 112px);
}

/* mssing style in react table pagination */
.react-bootstrap-table-pagination-list-hidden {
  display: none;
}

.claimShowDetail dt {
  text-align: right;
}

/* ::placeholder {
  color: #909;
} */

.sidebar-brand svg {
  fill: none;
}

.sidebar.toggled {
  margin-left: -250px;
}

th label.filter-label span {
  display: none;
}

.table-layout-fixed {
  table-layout: fixed;
}
`, "",{"version":3,"sources":["webpack://./src/css/local.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,wCAAwC;EACxC,2BAA2B;AAC7B;AACA;EACE,+BAA+B;AACjC;;AAEA,2CAA2C;AAC3C;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB;;AAEA;;GAEG;;AAEH;EACE,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".body {\n  -webkit-user-select: none;\n  -webkit-tap-highlight-color: transparent;\n  -webkit-touch-callout: none;\n}\n.content {\n  min-height: calc(100vh - 112px);\n}\n\n/* mssing style in react table pagination */\n.react-bootstrap-table-pagination-list-hidden {\n  display: none;\n}\n\n.claimShowDetail dt {\n  text-align: right;\n}\n\n/* ::placeholder {\n  color: #909;\n} */\n\n.sidebar-brand svg {\n  fill: none;\n}\n\n.sidebar.toggled {\n  margin-left: -250px;\n}\n\nth label.filter-label span {\n  display: none;\n}\n\n.table-layout-fixed {\n  table-layout: fixed;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
