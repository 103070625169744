import { userQuery, userListQuery } from '../graphql/user_queries';
import cleanCache from './clean_cache';

export const userList = (client, listData) => {
  listData.map((user) => {
    const data = { user };
    return client.writeQuery({
      query: userQuery,
      variables: { id: user.id.toString() },
      data,
    });
  });
  cleanCache(client.cache);
};

export const userCreate = (cache, { data: { userCreate: createData } }) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: userListQuery });
  } catch {
    cacheData = { userList: [] };
  }
  if (!cacheData) {
    cacheData = { useList: [] };
  }
  cache.writeQuery({
    query: userListQuery,
    data: { userList: [...cacheData.userList, createData] },
  });
  const data = { user: createData };
  cache.writeQuery({
    query: userQuery,
    variables: { id: createData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const userUpdate = (cache, { data: { userUpdate: updateData } }) => {
  const data = { user: updateData };
  cache.writeQuery({
    query: userQuery,
    variables: { id: updateData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const userDelete = (cache, { data: { userDelete: deleteData } }) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: userListQuery });
  } catch {
    cacheData = { userList: [] };
  }
  if (!cacheData) {
    cacheData = { useList: [] };
  }
  const data = {
    userList: cacheData.userList.filter((user) => deleteData.id !== user.id),
  };
  cache.writeQuery({ query: userListQuery, data });
  cache.evict({ fieldName: 'user', args: { id: deleteData.id.toString() } });
  cleanCache(cache);
};
