import mime from 'mime-types';

import compact from 'lodash.compact';
import cloneDeepWith from 'lodash.clonedeepwith';
import defaultTo from 'lodash.defaultto';
import get from 'lodash.get';
import includes from 'lodash.includes';
import last from 'lodash.last';
import isNil from 'lodash.isnil';
import omitBy from 'lodash.omitby';
import pick from 'lodash.pick';
import transform from 'lodash.transform';

import Api from './api';

function omitByNil(data) {
  return omitBy(data, isNil);
}

export function pickValues(data, whitelist = ['id']) {
  return pick(omitByNil(data), whitelist);
}

const integers = ['position'];

const floats = [
  'internalItemPricingLabour',
  'internalItemPricingMaterials',
  'itemQuantity',
  'internalItemQuantity',
  'internalMinimumChargeQuantity',
  'itemPricingUnitPrice',
  'internalItemPricingUnitPrice',
];

function inputTyper(value, key) {
  if (key && !(typeof value === 'object')) {
    if (includes(floats, key)) {
      return defaultTo(parseFloat(value), null);
    }
    if (includes(integers, key)) {
      return defaultTo(parseInt(value, 10), null);
    }
    if (value === '') {
      return null;
    }
  }
  return undefined;
}

export function coerceInput(data) {
  const coercedData = cloneDeepWith(data, inputTyper);
  return coercedData;
}

export function getExport(exportPath, displayName, args = {}) {
  const exportType = last(exportPath.split('.'));
  const contentType = mime.lookup(exportType);
  return Api.get(`/export/${exportPath}`, args, {
    responseType: 'arraybuffer',
  }).then((resp) => {
    const blob = new Blob([resp.data], { type: contentType });
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = window.URL.createObjectURL(blob);
    link.download = displayName || exportPath;
    link.click();
  });
}

const transformSubmitErrors = (errors) =>
  transform(
    errors,
    (result, values, key) => {
      /* eslint-disable no-param-reassign */
      if (Array.isArray(values) && values.length > 0) {
        if (typeof get(values, [0, 'message']) === 'string') {
          result[key] = compact(values.map((value) => value.message));
        } else {
          result[key] = values.map((value) => transformSubmitErrors(value));
        }
      }
    },
    {}
  );

export function handleSubmitError(err) {
  const errorMessage = get(err, ['graphQLErrors', '0', 'message']);
  let submitErrors = {};
  const graphqlErrors = get(
    err,
    ['graphQLErrors', '0', 'extensions', 'data', 'errors'],
    {}
  );
  if (Object.keys(graphqlErrors).length > 0) {
    submitErrors = transformSubmitErrors(graphqlErrors);
  }
  return {
    errorMessage: errorMessage || 'Something went wrong',
    submitErrors: Object.keys(submitErrors).length > 0 ? submitErrors : undefined,
  };
}

export const arrayWithItemAndKey = (array) =>
  array.map((item, index) => ({
    key: `item-${index}`,
    item,
  }));
