export const oacItemPickList = [
  'itemCategory',
  'itemCustom',
  'itemDescription',
  'itemMinimumChargeNotes',
  'itemNotes',
  'itemUnitOfMeasure',
  'itemValidationNotes',
  'minimumChargeQuantity',
  'minimumChargeUnitOfMeasure',
];

export const oacItemPricingPickList = [
  'itemPricingDateFrom',
  'itemPricingDateTo',
  'itemPricingGroup',
  'itemPricingFixed',
  'itemPricingUnitPrice',
  'itemPricingMaterials',
  'itemPricingLabour',
  'internalItemPricingUnitPrice',
  'internalItemPricingMaterials',
  'internalItemPricingLabour',
];

export const claimItemPickList = [
  'position',
  'itemFloor',
  'itemRoom',
  'itemTrade',
  'itemCostType',
  'oacCategoryId',
  'oacItemId',
  'oacItemPricingId',
  'itemQuantity',
  'internalItemQuantity',
  'internalMinimumChargeQuantity',
  'workCenterCategoryId',
  'workCenterItemId',
  'workCenterItemPricingId',
];

export const workCenterItemPickList = [
  'itemCostType',
  'itemCustom',
  'itemDescription',
  'itemMinimumChargeNotes',
  'itemNotes',
  'itemUnitOfMeasure',
  'itemValidationNotes',
  'minimumChargeQuantity',
  'minimumChargeUnitOfMeasure',
];
export const workCenterItemPricingPickList = ['itemPricingFixed', 'itemPricingUnitPrice'];

export const claimItemWhiteList = [
  ...oacItemPickList,
  ...oacItemPricingPickList,
  ...claimItemPickList,
  // not needed don't think.
  ...workCenterItemPickList,
  ...workCenterItemPricingPickList,
];
