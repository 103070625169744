import { useState, useEffect, useRef } from 'react';

const EditableCell = (props) => {
  const {
    data: { getValue, row, column, table },
    beforeSaveCell,
    formatter,
    style,
  } = props;

  const initialValue = getValue();
  const oldValue = useRef(getValue()).current;

  const updateTableData = (value) =>
    table.options.meta?.updateData(row.index, column.id, value);

  const [value, setValue] = useState(initialValue);
  const [newValue, setNewValue] = useState('');
  const [formattedValue, setFormattedValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setFormattedValue(formatter(initialValue));
  }, [initialValue]);

  useEffect(() => {
    setValue(formattedValue);
  }, [formattedValue]);

  useEffect(() => {
    if (!isFocused && newValue && oldValue !== newValue) {
      beforeSaveCell(oldValue, newValue, row.original.id, column.columnDef.accessorKey);
    }
  }, [isFocused]);

  const handleFocus = () => {
    setValue(initialValue);
    setIsFocused(true);
  };

  const handleBlur = () => {
    setNewValue(value);
    setValue(formattedValue);
    setIsFocused(false);
    updateTableData(value);
  };

  const handleChange = (e) => setValue(e.target.value);

  return (
    <input
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      style={{
        ...(style && style),
        border: isFocused ? '1px solid blue' : 'none',
        outline: 'none',
        padding: '5px',
      }}
    />
  );
};

export default EditableCell;
