import { Navigate, useRoutes } from 'react-router-dom';
import compact from 'lodash.compact';

import Page404 from './containers/page_404';

import Logout from './containers/logout';
import Login from './containers/login';

import ClaimList from './containers/claim_list';
import ClaimInspectedList from './containers/claim_inspected_list';
import ClaimReinspectionList from './containers/claim_reinspection_list';
import ClaimQccInspectionList from './containers/claim_qcc_inspection_list';
import ClaimClaimItemList from './containers/claim_claim_item_list';
import ClaimInternalNoteList from './containers/claim_internal_note_list';
import ClaimShow from './containers/claim_show';
import ClaimBuilderReportForm from './containers/claim_builder_report_form';
import ClaimQccInspectionForm from './containers/claim_qcc_inspection_form';
import ClaimFirstReinspectionForm from './containers/claim_first_reinspection_form';
import ClaimSecondReinspectionForm from './containers/claim_second_reinspection_form';
import ClaimClaimImageList from './containers/claim_claim_image_list';
import OacItemList from './containers/oac_item_list';
import OacReferralList from './containers/oac_referral_list';
import UserList from './containers/user_list';
import UserForm from './containers/user_form';
import WorkCenterCategoryList from './containers/work_center_category_list';

import AuthWrapper from './layouts/auth_wrapper';
import AuthLayout from './layouts/auth_layout';
import FluidAndScrollLayout from './layouts/fluid_and_scroll_layout';

const auth = (Component) => (
  <AuthWrapper>
    <Component />
  </AuthWrapper>
);

const restfulAuthRoutes = ({
  path,
  listElement,
  showElement,
  newElement,
  editElement,
  singleton = false,
}) => ({
  path,
  children: compact([
    newElement && {
      path: 'new',
      element: auth(newElement),
    },
    showElement && {
      path: singleton ? '' : ':id',
      element: auth(showElement),
    },
    editElement && {
      path: singleton ? 'edit' : ':id/edit',
      element: auth(editElement),
    },
    !singleton &&
      listElement && {
        path: '',
        element: auth(listElement),
      },
  ]),
});

const AppRoutes = () =>
  useRoutes([
    {
      path: '/',
      element: <Navigate to="/claims" />,
    },
    {
      path: '/logout',
      element: <Navigate to="/auth/logout" />,
    },
    {
      path: '/login',
      element: <Navigate to="/auth/login" />,
    },
    {
      path: '/404',
      element: <Page404 />,
    },
    {
      path: '/auth',
      element: <AuthLayout />,
      children: [
        {
          path: 'login',
          element: <Login />,
        },
        {
          path: 'logout',
          element: <Logout />,
        },
      ],
    },
    {
      element: <FluidAndScrollLayout />,
      children: [
        restfulAuthRoutes({
          path: '/users',
          listElement: UserList,
          newElement: UserForm,
          editElement: UserForm,
        }),
        restfulAuthRoutes({
          path: '/claims/:id/qcc_inspection',
          singleton: true,
          editElement: ClaimQccInspectionForm,
        }),
        restfulAuthRoutes({
          path: '/claims/:id/first_reinspection',
          singleton: true,
          editElement: ClaimFirstReinspectionForm,
        }),
        restfulAuthRoutes({
          path: '/claims/:id/second_reinspection',
          singleton: true,
          editElement: ClaimSecondReinspectionForm,
        }),
        restfulAuthRoutes({
          path: '/claims/:id/builder_report',
          singleton: true,
          editElement: ClaimBuilderReportForm,
        }),
        restfulAuthRoutes({
          path: '/claims/:id/claim_items',
          listElement: ClaimClaimItemList,
        }),
        restfulAuthRoutes({
          path: '/claims/:id/claim_images',
          listElement: ClaimClaimImageList,
        }),
        restfulAuthRoutes({
          path: '/claims/:id/internal_notes',
          listElement: ClaimInternalNoteList,
        }),
        restfulAuthRoutes({
          path: '/claims',
          listElement: ClaimList,
          showElement: ClaimShow,
        }),
        restfulAuthRoutes({
          path: '/inspected',
          listElement: ClaimInspectedList,
        }),
        restfulAuthRoutes({
          path: '/reinspections',
          listElement: ClaimReinspectionList,
        }),
        restfulAuthRoutes({
          path: '/qcc_inspections',
          listElement: ClaimQccInspectionList,
        }),
        restfulAuthRoutes({
          path: '/oac_referrals',
          listElement: OacReferralList,
        }),
        restfulAuthRoutes({
          path: '/oac_items',
          listElement: OacItemList,
        }),
        restfulAuthRoutes({
          path: '/work_center_categories',
          listElement: WorkCenterCategoryList,
        }),
      ],
    },
    {
      path: '/*',
      element: <Navigate to="/404" />,
    },
  ]);

export default AppRoutes;
