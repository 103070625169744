export const damageAssessmentRequires = [];

export const damageAssessmentConditionalRules = [
  {
    if: {
      required: ['damageAssessmentEvidenceOfPreexistingDamage'],
    },
    then: {
      required: ['damageAssessmentEvidenceOfPreexistingDamageExplanation'],
    },
  },
  {
    if: {
      required: ['damageAssessmentAlternativeAccomodationRecommended'],
    },
    then: {
      required: [
        'damageAssessmentAlternativeAccomodationRecommendedReason',
        'damageAssessmentAlternativeAccomodationRecommendedNights',
      ],
    },
  },
];
