import firstReinspectionFragment from '../fragments/first_reinspection_type_base_fragment';
import cleanCache from './clean_cache';

export const firstReinspectionUpdate = (
  cache,
  { data: { firstReinspectionUpdate: updateData } }
) => {
  const data = updateData;
  cache.writeFragment({
    fragment: firstReinspectionFragment,
    fragmentName: 'firstReinspectionFragment',
    id: `FirstReinspectionType-${updateData.id}`,
    data,
  });
  cleanCache(cache);
};
