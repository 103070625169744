import { Card } from 'react-bootstrap';
import Field from '../form/rff_field';
import InputField from '../form/input_field';
import CheckboxInputField from '../form/checkbox_input_field';

export default function ClaimReinspectionFormFields({ form, onFocusModal }) {
  return (
    <Card>
      <Card.Body>
        <Field
          name="description"
          component={InputField}
          labelWidth={4}
          inputWidth={8}
          asElement="textarea"
          size="lg"
          rows={2}
          customOnFocus={onFocusModal(form)}
        >
          Report and Scope
        </Field>
        <Field
          name="tradesmanOnSite"
          component={InputField}
          labelWidth={4}
          inputWidth={8}
          asElement="textarea"
          size="lg"
          rows={2}
          customOnFocus={onFocusModal(form)}
        >
          Tradesman On Site
        </Field>
        <Field
          name="qualityIssues"
          component={InputField}
          labelWidth={4}
          inputWidth={8}
          asElement="textarea"
          size="lg"
          rows={2}
          helpText="Is there any remedial work required? Issues with workmanship? What is the damage?"
          customOnFocus={onFocusModal(form)}
        >
          Quality Issues
        </Field>
        <Field
          name="additionalWork"
          component={InputField}
          labelWidth={4}
          inputWidth={8}
          asElement="textarea"
          size="lg"
          rows={2}
          helpText="Still to be completed, do the repairs reflect the scope of works?"
          customOnFocus={onFocusModal(form)}
        >
          Additional Work
        </Field>
        <Field
          name="insuredHappyOverall"
          component={InputField}
          labelWidth={4}
          inputWidth={8}
          asElement="textarea"
          size="lg"
          rows={2}
          helpText="Is the insured happy with the overall repairs, service and timeliness?"
          customOnFocus={onFocusModal(form)}
        >
          Customer Overall Happiness
        </Field>
        <Field
          name="insuredHappyTradesmanClean"
          component={InputField}
          labelWidth={4}
          inputWidth={8}
          asElement="textarea"
          size="lg"
          rows={2}
          helpText="Is the insured happy with the tradesman, was the tradesman clean and tidy?"
          customOnFocus={onFocusModal(form)}
        >
          Tradesman Tidiness
        </Field>
        <Field
          name="insuredHappyTradesmanPolite"
          component={InputField}
          labelWidth={4}
          inputWidth={8}
          asElement="textarea"
          size="lg"
          rows={2}
          helpText="Was the tradesman polite, courteous and explained what he was doing?"
          customOnFocus={onFocusModal(form)}
        >
          Tradesman Professionalism
        </Field>
        <Field
          name="insuredGeneralComments"
          component={InputField}
          labelWidth={4}
          inputWidth={8}
          asElement="textarea"
          size="lg"
          rows={2}
          customOnFocus={onFocusModal(form)}
        >
          Customer General Comments
        </Field>
        <Field
          type="checkbox"
          name="updatedSketch"
          component={CheckboxInputField}
          size="lg"
          labelWidth={4}
          inputWidth={8}
        >
          Sketch Updated?
        </Field>
        <Field
          type="checkbox"
          name="additionalPhotos"
          component={CheckboxInputField}
          size="lg"
          labelWidth={4}
          inputWidth={8}
        >
          Additional Photo&apos;s Added?
        </Field>
        <Field
          type="checkbox"
          name="calledTradesman"
          component={CheckboxInputField}
          size="lg"
          labelWidth={4}
          inputWidth={8}
        >
          Tradesman Called (if required)
        </Field>
        <Field
          type="checkbox"
          name="calledLossAdjustor"
          component={CheckboxInputField}
          size="lg"
          labelWidth={4}
          inputWidth={8}
        >
          Loss Adjustor Called (if required)
        </Field>
      </Card.Body>
    </Card>
  );
}
