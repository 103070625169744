/* eslint-disable react/jsx-props-no-spreading */
import { Row, Col, Form } from 'react-bootstrap';
import { isInvalid, validationText } from './helpers';
import DropZone from './drop_zone';

const defaultProps = {
  labelWidth: 0,
  inputWidth: 12,
  defaultSelectOption: true,
  helpText: '',
  helpBlock: true,
  plainText: false,
};

export default function DropZoneField(props) {
  const {
    input,
    meta,
    groupStyle,
    groupClassName,
    labelWidth,
    inputWidth,
    helpText,
    helpBlock,
    filenames,
    handleOnDrop,
    allowMultiple,
    children,
  } = { ...defaultProps, ...props };

  const invalid = isInvalid(meta);

  const innerContent = (
    <>
      {labelWidth === 0 && children && <Form.Label>{children}</Form.Label>}
      <DropZone
        filenames={filenames}
        handleOnDrop={handleOnDrop}
        multiple={!!allowMultiple}
      />
      {helpBlock && invalid && (
        <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
          {validationText(meta, helpText)}
        </Form.Control.Feedback>
      )}
      {helpBlock && helpText && !invalid && (
        <div style={{ width: '100%', marginTop: '0.25rem', fontSize: '80%' }}>
          {helpText}
        </div>
      )}
    </>
  );

  return (
    <Form.Group
      controlId={input.name}
      {...(groupStyle && { style: groupStyle })}
      {...(groupClassName && { className: groupClassName })}
      {...(labelWidth > 0 && { as: Row })}
      {...(labelWidth === 0 && { as: Col })}
    >
      {labelWidth > 0 ? (
        <>
          <Form.Label column sm={labelWidth}>
            {children}
          </Form.Label>
          <Col sm={inputWidth}>{innerContent}</Col>
        </>
      ) : (
        innerContent
      )}
    </Form.Group>
  );
}
