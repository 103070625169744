const claimItemFormSchema = {
  type: 'object',
  required: ['itemFloor', 'itemDescription', 'itemRoom'],
  anyOf: [
    {
      required: ['itemTrade'],
    },
    {
      required: ['oacCategoryId'],
    },
    {
      required: ['workCenterCategoryId'],
    },
  ],
  allOf: [
    {
      if: {
        required: ['oacCategoryId'],
      },
      then: {
        required: ['oacItemId'],
      },
    },
    {
      if: {
        required: ['oacItemId'],
      },
      then: {
        allOf: [
          {
            required: ['itemUnitOfMeasure', 'itemPricingUnitPrice'],
            if: {
              properties: {
                itemUnitOfMeasure: { const: 'Min Charge' },
              },
            },
            // note internalMinimumCharge Quantity validation is handled on field.
            then: {
              anyOf: [
                {
                  required: ['itemQuantity'],
                },
                {
                  properties: {
                    itemQuantity: { const: 0 },
                  },
                },
              ],
            },
            else: {
              required: ['itemQuantity'],
            },
          },
        ],
      },
    },
    // {
    //   if: {
    //     required: ['workCenterCategoryId'],
    //   },
    //   then: {
    //     required: ['workCenterItemId'],
    //   },
    // },
    {
      if: {
        required: ['workCenterItemId'],
      },
      then: {
        allOf: [
          {
            required: ['itemCostType', 'itemUnitOfMeasure', 'itemPricingUnitPrice'],
            if: {
              properties: {
                itemUnitOfMeasure: { const: 'Min Charge' },
              },
            },
            // note internalMinimumCharge Quantity validation is handled on field.
            then: {
              anyOf: [
                {
                  required: ['itemQuantity'],
                },
                {
                  properties: {
                    itemQuantity: { const: 0 },
                  },
                },
              ],
            },
            else: {
              required: ['itemQuantity'],
            },
          },
        ],
      },
    },
  ],
};

export { claimItemFormSchema };
