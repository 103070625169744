export const tenancyRequires = [];

export const tenancyConditionalRules = [
  {
    if: {
      // boolean so only this required
      required: ['tenancyTenantInformedLandlordOrManager'],
    },
    then: {
      required: ['tenancyTenantInformedLandlordOrManagerWhen'],
    },
  },
  {
    if: {
      required: ['tenancy'],
    },
    then: {
      required: ['tenancyTenantName', 'tenancyTenantPhone'],
    },
  },
];
