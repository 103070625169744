const workCenterItemWhiteList = [
  // 'workCenterCategoryId',
  'position',
  'itemDescription',
  'itemNotes',
  'itemCostType',
  'itemUnitOfMeasure',
  'itemValidationNotes',
  'itemMinimumChargeNotes',
  'itemCustom',
  'minimumChargeUnitOfMeasure',
  'minimumChargeQuantity',
  // 'workCenterItemPricings',
];

export default workCenterItemWhiteList;
