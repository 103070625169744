import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash.get';
import set from 'lodash.set';

const initialState = {
  isAuthenticated: false,
  isAuthenticating: false,
  token: '',
  userId: '',
  tenant: '',
  connectionKey: '',
  user: {},
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authSet(state, action) {
      const user = get(action, ['payload', 'user'], {});
      if (user.id) {
        set(
          action,
          ['payload', 'user', 'limited'],
          user.email === 'gordon.king02@gmail.com'
        );
      }
      return {
        ...initialState,
        ...action.payload,
      };
    },
    authReset() {
      return initialState;
    },
  },
});

export const { authSet, authReset } = authSlice.actions;
export default authSlice.reducer;
