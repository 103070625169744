const getTenantConfig = (tenant) => {
  const tenantConfig = {
    ri: {
      tenantLongName: 'Reinstatement Specialists Limited',
      tenantShortName: 'RSL',
      tenantHasReinspections: true,
      tenantHasQccInspections: false,
    },
    gps: {
      tenantLongName: 'Grayburn Property Services Limited',
      tenantShortName: 'GPS',
      tenantHasReinspections: false,
      tenantHasQccInspections: true,
    },
  }[tenant];
  return (
    { tenant, ...tenantConfig } || {
      tenant: '',
      tenantLongName: '',
      tenantShortName: '',
      tenantHasReinspections: false,
      tenantHasQccInspections: false,
    }
  );
};

export default getTenantConfig;
