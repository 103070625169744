import merge from 'lodash.merge';

import { claimQuery } from '../graphql/claim_queries';
import cleanCache from './clean_cache';

export const claimList = (client, listData) => {
  listData.map((claim) => {
    const data = { claim };
    return client.writeQuery({
      query: claimQuery,
      variables: { id: claim.id.toString() },
      data,
    });
  });
  cleanCache(client.cache);
};

export const claimReset = (cache, { data: { claimReset: resetData } }) => {
  let currentData;
  try {
    currentData = cache.readQuery({
      query: claimQuery,
      variables: { id: resetData.id.toString() },
    });
  } catch {
    currentData = { claim: {} };
  }
  const data = { claim: merge({}, currentData.claim, resetData) };
  cache.writeQuery({
    query: claimQuery,
    variables: { id: resetData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const claimRiStatusUpdate = (
  cache,
  { data: { claimRiStatusUpdate: riStatusUpdateData } }
) => {
  let currentData;
  try {
    currentData = cache.readQuery({
      query: claimQuery,
      variables: { id: riStatusUpdateData.id.toString() },
    });
  } catch {
    currentData = { claim: {} };
  }
  const data = { claim: merge({}, currentData.claim, riStatusUpdateData) };
  cache.writeQuery({
    query: claimQuery,
    variables: { id: riStatusUpdateData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const claimSubmit = (cache, { data: { claimSubmit: submitData } }) => {
  let currentData;
  try {
    currentData = cache.readQuery({
      query: claimQuery,
      variables: { id: submitData.id.toString() },
    });
  } catch {
    currentData = { claim: {} };
  }
  const data = { claim: merge({}, currentData.claim, submitData) };
  cache.writeQuery({
    query: claimQuery,
    variables: { id: submitData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const claimUpdate = (cache, { data: { claimUpdate: updateData } }) => {
  const data = { claim: updateData };
  cache.writeQuery({
    query: claimQuery,
    variables: { id: updateData.id.toString() },
    data,
  });
  cleanCache(cache);
};
