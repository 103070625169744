import {
  Modal,
  Form,
  Button,
  Row,
  Col,
  ButtonToolbar,
  ButtonGroup,
} from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import arrify from 'arrify';

import curry from 'lodash.curry';
import clone from 'lodash.clone';
import get from 'lodash.get';
import first from 'lodash.first';
import map from 'lodash.map';
import merge from 'lodash.merge';
import omit from 'lodash.omit';
import pick from 'lodash.pick';
import pickBy from 'lodash.pickby';
import round from 'lodash.round';
import sum from 'lodash.sum';
import values from 'lodash.values';
import reverse from 'lodash.reverse';
import sortBy from 'lodash.sortby';

import SubmitButtonSpinner from '../components/submit_button_spinner';
import Field from '../components/form/rff_field';
import OnChangeField from '../components/form/rff_on_change_field';
import InputField from '../components/form/input_field';
import InvalidBlock from '../components/form/invalid_block';
import LabelBlock from '../components/form/label_block';
import FocusModalInputField from '../components/form/focus_modal_input_field';
import { pickValues } from '../lib/utils';
import { claimItemFormValidator } from '../validators';

import {
  claimItemWhiteList,
  claimItemPickList,
  workCenterItemPickList,
  workCenterItemPricingPickList,
} from '../white_lists/claim_item_form_white_lists';

const ClaimItemFormWorkCenter = ({
  open,
  selectedFloor,
  selectedRoom,
  lastClaimItem,
  workCenterCategoryList,
  enums,
  claimItem,
  handleSetModalToggle,
  handleClaimItemSubmit,
  workCenterMinChargeAccumulator,
}) => {
  let clickedSubmitButton = 'new';

  // eslint-disable-next-line arrow-body-style
  const getInitialValues = () => {
    return {
      ...{ itemFloor: selectedFloor },
      ...{ itemRoom: selectedRoom },
      // ...{ itemUnitOfMeasure: 'Each' },
      ...(lastClaimItem && pickValues(lastClaimItem, ['workCenterCategoryId'])),
      ...pickValues(claimItem, claimItemWhiteList),
    };
  };

  const onHide = () => handleSetModalToggle && handleSetModalToggle();
  const onCancel = () => handleSetModalToggle && handleSetModalToggle();

  const sortedWorkCenterItemPricings = (workCenterItem) =>
    reverse(
      sortBy(clone(get(workCenterItem, ['workCenterItemPricings'], [])), ['createdAt'])
    );

  const getSelectedWorkCenterCategoryId = (workCenterCategoryId) => {
    if (workCenterCategoryId) {
      return [
        pick(
          workCenterCategoryList.find(
            (ic) => ic.id === parseInt(workCenterCategoryId, 10)
          ),
          ['id', 'workCenterName']
        ),
      ];
    }
    return [];
  };

  const getWorkCenterItems = (workCenterCategoryId) => {
    if (workCenterCategoryId) {
      return workCenterCategoryList
        .find((ic) => ic.id === parseInt(workCenterCategoryId, 10))
        .workCenterItems.map(({ id, itemDescription }) => ({
          id,
          name: itemDescription,
        }));
    }
    return [];
  };

  const getClaimItemMinChargeAccumulator = (workCenterItemId) => {
    if (!workCenterItemId) {
      return 0;
    }
    const claimItems = get(
      workCenterMinChargeAccumulator,
      [parseInt(workCenterItemId, 10)],
      {}
    );
    return sum(
      map(
        values(pickBy(claimItems, (_, key) => parseInt(key, 10) !== claimItem.id)),
        first
      )
    );
  };

  const getTotalClaimItemMinChargeAccumulator = ({
    workCenterItemId,
    internalMinimumChargeQuantity = 0,
  }) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    round(
      getClaimItemMinChargeAccumulator(workCenterItemId) +
        (Number.isNaN(parseFloat(internalMinimumChargeQuantity))
          ? 0
          : parseFloat(internalMinimumChargeQuantity)),
      2
    );

  const validateInternalMinimumChargeQuantity = (
    value = 0,
    { workCenterItemId, minimumChargeQuantity, itemUnitOfMeasure }
  ) => {
    if (itemUnitOfMeasure === 'Min Charge') {
      const currentValue = getClaimItemMinChargeAccumulator(workCenterItemId);
      if (round(currentValue + parseFloat(value), 2) > minimumChargeQuantity) {
        return [
          `Minimum Charge Quantity cannot be higher than ${round(
            minimumChargeQuantity - currentValue,
            2
          )} `,
        ];
      }
      if (Number.isNaN(parseFloat(value)) || value === 0) {
        return ['should have required property "internalMinimumChargeQuantity"'];
      }
    }
    return undefined;
  };

  const onFormSubmit = (data, form) => {
    const submitData = clone(data);
    submitData.itemTrade = get(
      workCenterCategoryList.find(
        (ic) => ic.id === parseInt(submitData.workCenterCategoryId, 10)
      ),
      ['workCenterName']
    );
    handleClaimItemSubmit(submitData, clickedSubmitButton);
    if (clickedSubmitButton === 'new') {
      form.restart(pick(submitData, ['itemFloor', 'itemRoom', 'workCenterCategoryId']));
    }
  };

  const onItemCostTypeChange = (form, values) => {
    const { itemCostType } = values;
    if (itemCostType === 'Incl.') {
      form.batch(() => {
        form.change('itemQuantity', 1);
        form.change('itemPricingUnitPrice', '0.00');
      });
    }
  };

  const onWorkCenterCategoryIdChange = (form) => {
    form.batch(() => {
      form.change('workCenterItemId', '');
      form.change('workCenterItemPricingId', '');
      form.change('itemDescription', '');
    });
  };

  const onWorkCenterItemIdChange = (form, values) => {
    const { workCenterCategoryId, workCenterItemId } = values;
    let newValues = {};
    if (workCenterCategoryId && workCenterItemId) {
      const workCenterItem = workCenterCategoryList
        .find((wc) => wc.id === parseInt(workCenterCategoryId, 10))
        .workCenterItems.find((wci) => wci.id === parseInt(workCenterItemId, 10));
      newValues = merge(
        pick(values, claimItemPickList),
        pick(workCenterItem, workCenterItemPickList)
      );
      newValues.itemQuantity = '';
      if (!workCenterItem.itemCustom) {
        const workCenterItemPricing = first(sortedWorkCenterItemPricings(workCenterItem));
        newValues = merge(
          newValues,
          pick(workCenterItemPricing, workCenterItemPricingPickList)
        );
        newValues.workCenterItemPricingId = workCenterItemPricing.id;
        if (workCenterItemPricing.itemPricingFixed) {
          if (workCenterItem.itemUnitOfMeasure === 'Min Charge') {
            const currentMinChargeItems = getClaimItemMinChargeAccumulator(
              workCenterItem.id
            );
            if (currentMinChargeItems > 0) {
              newValues.itemQuantity = 0;
            } else {
              newValues.itemQuantity = 1;
            }
          } else {
            newValues.itemQuantity = 1;
          }
        }
      }
    } else {
      newValues = merge(pick(values, claimItemPickList));
      newValues.workCenterItemPricingId = '';
      newValues.itemCostType = '';
      newValues.itemQuantity = '';
    }
    newValues.internalItemQuantity = '';
    newValues.internalMinimumChargeQuantity = '';
    form.batch(() => {
      form.reset(omit(newValues, ['itemDescription']));
      form.change('itemDescription', newValues.itemDescription); // make the form changed and dirty so can immediately submit
    });
  };

  const onTypeaheadBlur = curry((form, name, e) => {
    e.preventDefault();
    form.mutators.setFieldTouched(name, true);
  });

  const onTypeaheadChange = curry((form, name, value) => {
    let formValue = value[0] || null;
    if (formValue && formValue.id) {
      formValue = formValue.id;
    }
    form.change(name, formValue);
  });

  const onFloatBlur = curry((form, name, onBlur, e) => {
    const value = Number.isNaN(parseFloat(e.target.value))
      ? ''
      : round(parseFloat(e.target.value), 2);
    form.change(name, value);
    onBlur(e);
  });

  const renderContent = () => (
    <>
      <Modal.Header>
        <Modal.Title>{claimItem.id ? 'Update Item' : 'Create Item'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FinalForm
          initialValues={getInitialValues()}
          onSubmit={onFormSubmit}
          validate={claimItemFormValidator}
          mutators={{ setFieldTouched }}
        >
          {({ handleSubmit, pristine, form, submitting, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Field name="itemRoom">{() => null}</Field>
              <Field name="workCenterCategoryId">{() => null}</Field>
              <OnChangeField name="workCenterCategoryId">
                {() => onWorkCenterCategoryIdChange(form)}
              </OnChangeField>
              <OnChangeField name="workCenterItemId">
                {() => onWorkCenterItemIdChange(form, values)}
              </OnChangeField>
              <OnChangeField name="itemCostType">
                {() => onItemCostTypeChange(form, values)}
              </OnChangeField>
              <Row className="mb-3">
                <Field
                  name="itemFloor"
                  component={InputField}
                  asElement="select"
                  selectOptions={enums.ItemFloors.map((floor) => ({
                    id: floor,
                    name: floor,
                  }))}
                  defaultSelectOptionName="select floor..."
                  labelWidth={0}
                  inputWidth={4}
                  size="md"
                >
                  Floor
                </Field>
                <InputField
                  size="md"
                  labelWidth={0}
                  inputWidth={4}
                  input={{
                    name: 'itemRoom',
                  }}
                  innerContent={
                    <>
                      <LabelBlock size="md">Room</LabelBlock>
                      <Typeahead
                        id="itemroom-typeahead-single"
                        labelKey="itemRoom"
                        onBlur={onTypeaheadBlur(form, 'itemRoom')}
                        onChange={onTypeaheadChange(form, 'itemRoom')}
                        options={enums.ItemRooms}
                        placeholder="select room..."
                        selected={arrify(values.itemRoom)}
                      />
                      <InvalidBlock meta={form.getFieldState('itemRoom') || {}} force />
                    </>
                  }
                />
                <InputField
                  size="md"
                  labelWidth={0}
                  inputWidth={4}
                  input={{
                    name: 'workCenterCategoryId',
                  }}
                  innerContent={
                    <>
                      <LabelBlock size="md">Work Center Category</LabelBlock>
                      <Typeahead
                        id="work-center-category-typeahead-single"
                        labelKey="workCenterName"
                        onBlur={onTypeaheadBlur(form, 'workCenterCategoryId')}
                        onChange={onTypeaheadChange(form, 'workCenterCategoryId')}
                        options={workCenterCategoryList.map(({ id, workCenterName }) => ({
                          id,
                          workCenterName,
                        }))}
                        placeholder="select workcenter category..."
                        selected={getSelectedWorkCenterCategoryId(
                          values.workCenterCategoryId
                        )}
                      />
                      <InvalidBlock
                        meta={form.getFieldState('workCenterCategoryId') || {}}
                        force
                      />
                    </>
                  }
                />
              </Row>
              <Field
                name="workCenterItemId"
                component={InputField}
                asElement="select"
                selectOptions={getWorkCenterItems(values.workCenterCategoryId)}
                defaultSelectOptionName="select item..."
                size="md"
              >
                Work Center Item
              </Field>
              <hr />
              <Field
                name="itemDescription"
                component={FocusModalInputField}
                asElement="textarea"
                rows={2}
                form={form}
                size="md"
              >
                Description
              </Field>
              {values.itemNotes && (
                <InputField
                  input={{
                    name: 'itemNotes',
                    value: values.ItemNotes,
                  }}
                  meta={{}}
                  plainText
                  helpBlock={false}
                  type="text"
                  size="md"
                  noTab
                >
                  Notes
                </InputField>
              )}
              {values.itemValidationNotes && (
                <InputField
                  input={{
                    name: 'itemNotes',
                    value: values.itemValidationNotes,
                  }}
                  meta={{}}
                  plainText
                  helpBlock={false}
                  type="text"
                  size="md"
                  noTab
                >
                  Validation Notes
                </InputField>
              )}
              {values.itemMinimumChargeNotes && (
                <InputField
                  input={{
                    name: 'itemMinimumChargeNotes',
                    value: values.itemMinimumChargeNotes,
                  }}
                  meta={{}}
                  plainText
                  helpBlock={false}
                  type="text"
                  size="md"
                  noTab
                >
                  Min Charge Notes
                </InputField>
              )}
              <hr />
              <Row className="mb-3">
                <Field
                  name="itemCostType"
                  component={InputField}
                  asElement="select"
                  selectOptions={enums.ItemCostTypes.map((costType) => ({
                    id: costType,
                    name: costType,
                  }))}
                  defaultSelectOptionName="select cost type..."
                  disabled={values.workCenterItemId && values.itemPricingFixed}
                  labelWidth={0}
                  inputWidth={3}
                >
                  Cost Type
                </Field>
                <Field
                  name="itemQuantity"
                  component={InputField}
                  customOnBlur={onFloatBlur(form)}
                  disabled={
                    values.workCenterItemId && values.itemUnitOfMeasure === 'Min Charge'
                  }
                  labelWidth={0}
                  inputWidth={3}
                >
                  Quantity
                </Field>
                <Field
                  name="itemUnitOfMeasure"
                  component={InputField}
                  asElement="select"
                  selectOptions={enums.ItemUnitOfMeasures.map((unit) => ({
                    id: unit,
                    name: unit,
                  }))}
                  defaultSelectOptionName="select unit of measure..."
                  disabled={values.workCenterItemId && values.itemPricingFixed}
                  labelWidth={0}
                  inputWidth={3}
                >
                  Unit of Measure
                </Field>
                <Field
                  name="itemPricingUnitPrice"
                  component={InputField}
                  prepend="$"
                  disabled={
                    values.workCenterItemId &&
                    values.itemPricingFixed &&
                    values.itemCostType !== 'Rate'
                  }
                  labelWidth={0}
                  inputWidth={3}
                >
                  Unit Price
                </Field>
              </Row>
              {values.itemUnitOfMeasure === 'Min Charge' && (
                <Row className="mb-3">
                  <Field
                    name="internalMinimumChargeQuantity"
                    component={InputField}
                    validate={validateInternalMinimumChargeQuantity}
                    customOnBlur={onFloatBlur(form)}
                    labelWidth={0}
                    inputWidth={0}
                  >
                    {`This Min Charge (${values.minimumChargeUnitOfMeasure})`}
                  </Field>
                  <InputField
                    input={{
                      name: 'usedMinimumChargeQuantity',
                      value: getClaimItemMinChargeAccumulator(values.workCenterItemId),
                    }}
                    meta={{}}
                    plainText
                    helpBlock={false}
                    type="text"
                    noTab
                    labelWidth={0}
                    inputWidth={0}
                  >
                    Other Min Charge
                  </InputField>
                  <InputField
                    input={{
                      name: 'totalMinimumChargeQuantity',
                      value: getTotalClaimItemMinChargeAccumulator(values),
                    }}
                    meta={{}}
                    plainText
                    helpBlock={false}
                    type="text"
                    noTab
                    labelWidth={0}
                    inputWidth={0}
                  >
                    Total Min Charge
                  </InputField>
                  <InputField
                    input={{
                      name: 'minimumChargeQuantity',
                      value: values.minimumChargeQuantity,
                    }}
                    meta={{}}
                    plainText
                    helpBlock={false}
                    type="text"
                    noTab
                    labelWidth={0}
                    inputWidth={0}
                  >
                    Allowed Min Charge
                  </InputField>
                  <InputField
                    input={{
                      name: 'minimumChargeUnitOfMeasure',
                      value: values.minimumChargeUnitOfMeasure,
                    }}
                    meta={{}}
                    plainText
                    helpBlock={false}
                    type="text"
                    noTab
                    labelWidth={0}
                    inputWidth={0}
                  >
                    Min Charge UofM
                  </InputField>
                </Row>
              )}
              <hr />
              <Form.Group as={Row}>
                <Col sm={12}>
                  <ButtonToolbar style={{ justifyContent: 'flex-end' }}>
                    <ButtonGroup className="me-2">
                      <Button variant="danger" onClick={onCancel} disabled={submitting}>
                        Cancel
                      </Button>
                    </ButtonGroup>
                    <ButtonGroup className="me-2">
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={pristine || submitting}
                        onClick={(event) => {
                          clickedSubmitButton = 'new';
                          handleSubmit(event);
                        }}
                      >
                        {submitting && <SubmitButtonSpinner />}
                        {claimItem.id ? 'Update and New' : 'Create and New'}
                      </Button>
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={pristine || submitting}
                        onClick={(event) => {
                          clickedSubmitButton = 'close';
                          handleSubmit(event);
                        }}
                      >
                        {submitting && <SubmitButtonSpinner />}
                        {claimItem.id ? 'Update and Close' : 'Create and Close'}
                      </Button>
                    </ButtonGroup>
                  </ButtonToolbar>
                </Col>
              </Form.Group>
            </form>
          )}
        </FinalForm>
      </Modal.Body>
    </>
  );

  return (
    <div>
      <Modal show={open} onHide={onHide} size="lg" centered>
        {renderContent()}
      </Modal>
    </div>
  );
};

export default ClaimItemFormWorkCenter;
