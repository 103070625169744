import {
  Modal,
  Form,
  Button,
  Row,
  Col,
  ButtonToolbar,
  ButtonGroup,
} from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import arrify from 'arrify';

import curry from 'lodash.curry';
import clone from 'lodash.clone';

import SubmitButtonSpinner from '../components/submit_button_spinner';
import InputField from '../components/form/input_field';
import InvalidBlock from '../components/form/invalid_block';
import LabelBlock from '../components/form/label_block';
import Field from '../components/form/rff_field';

import { claimItemListCloneRoomFormValidator } from '../validators';

const ClaimClaimItemListCloneRoomForm = ({
  open,
  sourceFloor,
  sourceRoom,
  enums,
  handleSetModalToggle,
  handleCloneRoomSubmit,
}) => {
  // eslint-disable-next-line arrow-body-style
  const getInitialValues = () => ({
    destFloor: sourceFloor,
    destRoom: '',
  });

  const onHide = () => handleSetModalToggle && handleSetModalToggle();
  const onCancel = () => handleSetModalToggle && handleSetModalToggle();

  const onFormSubmit = (data) => {
    const submitData = clone(data);
    handleCloneRoomSubmit(submitData);
  };

  const onTypeaheadBlur = curry((form, name, e) => {
    e.preventDefault();
    form.mutators.setFieldTouched(name, true);
  });

  const onTypeaheadChange = curry((form, name, value) => {
    let formValue = value[0] || null;
    if (formValue && formValue.id) {
      formValue = formValue.id;
    }
    form.change(name, formValue);
  });

  const renderContent = () => (
    <>
      <Modal.Header>
        <Modal.Title>Select Floor and Room</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{`Source Floor: ${sourceFloor}`}</p>
        <p>{`Source Room: ${sourceRoom}`}</p>
        <FinalForm
          initialValues={getInitialValues()}
          onSubmit={onFormSubmit}
          mutators={{ setFieldTouched }}
          validate={claimItemListCloneRoomFormValidator}
        >
          {({ handleSubmit, pristine, form, submitting, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Field name="destRoom">{() => null}</Field>
              <Row className="mb-3">
                <Field
                  name="destFloor"
                  component={InputField}
                  asElement="select"
                  selectOptions={enums.ItemFloors.map((floor) => ({
                    id: floor,
                    name: floor,
                  }))}
                  defaultSelectOptionName="select floor..."
                  labelWidth={0}
                  inputWidth={4}
                  size="md"
                >
                  Floor
                </Field>
                <InputField
                  size="md"
                  labelWidth={0}
                  inputWidth={4}
                  input={{
                    name: 'destRoom',
                  }}
                  innerContent={
                    <>
                      <LabelBlock size="md">Room</LabelBlock>
                      <Typeahead
                        id="destroom-typeahead-single"
                        labelKey="destRoom"
                        onBlur={onTypeaheadBlur(form, 'destRoom')}
                        onChange={onTypeaheadChange(form, 'destRoom')}
                        options={enums.ItemRooms}
                        placeholder="select room..."
                        selected={arrify(values.destRoom)}
                      />
                      <InvalidBlock meta={form.getFieldState('destRoom') || {}} force />
                    </>
                  }
                />
              </Row>
              <hr />
              <Form.Group as={Row}>
                <Col sm={12}>
                  <ButtonToolbar style={{ justifyContent: 'flex-end' }}>
                    <ButtonGroup className="me-2">
                      <Button variant="danger" onClick={onCancel} disabled={submitting}>
                        Cancel
                      </Button>
                    </ButtonGroup>
                    <ButtonGroup className="me-2">
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={pristine || submitting}
                        onClick={handleSubmit}
                      >
                        {submitting && <SubmitButtonSpinner />}
                        Clone
                      </Button>
                    </ButtonGroup>
                  </ButtonToolbar>
                </Col>
              </Form.Group>
            </form>
          )}
        </FinalForm>
      </Modal.Body>
    </>
  );

  return (
    <div>
      <Modal show={open} onHide={onHide} size="md" centered>
        {renderContent()}
      </Modal>
    </div>
  );
};

export default ClaimClaimItemListCloneRoomForm;
